<template>
  <div class="container">
    <h2>{{ msg }}</h2>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      msg: 'JobViz: Skill-driven Visual Exploration of Job Advertisements',
    };
  }
};
</script>

<style scoped>
.container {
  display: inline;
  margin: 0;
  padding-left: 0;
  padding-top: 10px;
  height: 50px;
  text-align: left;
  background-color: #ffffff;
}
</style>