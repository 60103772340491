<template>
  <div id="Details">
    <h3>Post Detail View</h3>
    <div class="detailTable">
      <table v-if="id2 === ''" class="table">
        <thead v-for="(value,key,index) in id1_details" v-bind:key="index+999">
          <tr v-if="key!=='Skill Vector' && key !== 'Doc Id' && key !== 'link'" @click="changeContent(key)">
            <th>{{key}}</th>
            <th style="color: #007594" v-if="value === '(Click and expand details)'">{{value}}</th>
            <th v-else>{{value}}</th>
          </tr>
        </thead>
      </table>
      <table v-else class="table">
        <thead v-for="(value,key,index) in id1_details" v-bind:key="index+999">
          <tr v-if="key!=='Skill Vector' && key !== 'Doc Id' && key !== 'link'" @click="changeContent(key)">
            <th style="color: #007594" v-if="value === '(Click and expand details)'">{{value}}</th>
            <th v-else>{{value}}</th>
            <th>{{key}}</th>
            <th style="color: #007594" v-if="value === '(Click and expand details)'">{{id2_details[key]}}</th>
            <th v-else>{{id2_details[key]}}</th>
          </tr>
        </thead>
      </table>
    </div>

  </div>
</template>

<script>
import {getDetails} from "@/common/data-service";
import PipeService from "@/common/pipe-service";

export default {
  name: "Details",
  data(){
    return {
      id1:'',
      id2:'',

      id1_details: {},
      id2_details: {},

      record: {"id1": {"Company Information": "", "Job Duty": "", "Job Information": "", "Job Requirement": ""},
               "id2": {"Company Information": "", "Job Duty": "", "Job Information": "", "Job Requirement": ""}}

    }
  },
  methods:{
    changeContent(key){
      if (key === "Company Information" || key === "Job Duty" || key === "Job Information" || key === "Job Requirement") {
        if (this.id1_details[key] === "(Click and expand details)") {
          this.id1_details[key] = this.record["id1"][key];
          this.id2_details[key] = this.record["id2"][key];
        } else {
          this.id1_details[key] = "(Click and expand details)";
          this.id2_details[key] = "(Click and expand details)";
        }
      }
    },

    updateDetails(id, index) {
      if (index === 1) {
        this.id1 = id;
        getDetails(this.id1,(res) => {
          this.id1_details = res.data;
          this.record["id1"]["Company Information"] = this.id1_details["Company Information"];
          this.record["id1"]["Job Duty"] = this.id1_details["Job Duty"];
          this.record["id1"]["Job Information"] = this.id1_details["Job Information"];
          this.record["id1"]["Job Requirement"] = this.id1_details["Job Requirement"];
          this.id1_details["Company Information"] = "(Click and expand details)";
          this.id1_details["Job Duty"] = "(Click and expand details)";
          this.id1_details["Job Information"] = "(Click and expand details)";
          this.id1_details["Job Requirement"] = "(Click and expand details)";
        })
      } else {
        if (id !== "") {
          getDetails(id, (res) => {
            this.id2_details = res.data;
            this.record["id2"]["Company Information"] = this.id2_details["Company Information"];
            this.record["id2"]["Job Duty"] = this.id2_details["Job Duty"];
            this.record["id2"]["Job Information"] = this.id2_details["Job Information"];
            this.record["id2"]["Job Requirement"] = this.id2_details["Job Requirement"];
            this.id2_details["Company Information"] = "(Click and expand details)";
            this.id2_details["Job Duty"] = "(Click and expand details)";
            this.id2_details["Job Information"] = "(Click and expand details)";
            this.id2_details["Job Requirement"] = "(Click and expand details)";
          })
          this.id1_details["Company Information"] = "(Click and expand details)";
          this.id1_details["Job Duty"] = "(Click and expand details)";
          this.id1_details["Job Information"] = "(Click and expand details)";
          this.id1_details["Job Requirement"] = "(Click and expand details)";
        }
        // else {
        //   this.id1_details["Company Information"] = this.record["id1"]["Company Information"];
        //   this.id1_details["Job Duty"] = this.record["id1"]["Job Duty"];
        //   this.id1_details["Job Information"] = this.record["id1"]["Job Information"];
        //   this.id1_details["Job Requirement"] = this.record["id1"]["Job Requirement"];
        // }
        this.id2 = id;
      }

    }
  },
  mounted(){
    this.updateDetails('0o14-cc59e4918a53ac043dc58d469fdc928c', 1);
    this.updateDetails('0o14-cc59e4918a53ac043dc58d469fdc928c', 2);
    PipeService.$on('id1',data=>{
      this.updateDetails(data, 1);
    })
    PipeService.$on('id2',data=>{
      this.updateDetails(data, 2);
    })
  }
}
</script>

<style scoped>
#Details{
  position: absolute;
  width: 800px;
  height: 340px;
  top: 780px;
  left: 1230px;
  border: 2px solid rgba(255, 255, 255, 0);
  border-radius: 5px;
  background-color: #ffffff;
}

.detailTable {
  height: 280px;
  overflow: auto;
  width: 780px;
  margin-left: 10px;
}

.detailTable::-webkit-scrollbar {
  width: 0;
}

tr{
  cursor: default;
}
</style>
