<template>
  <div id="Overview">
    <div id="title"><h3>Skill-job Overview</h3></div>
    <img id="overview-legend" src="../assets/OverviewLegend7.svg"/>
    <div id="button_box">
      <div id="line1"></div>
      <div id="line2">
        <h5>Posts sorted by </h5>
        <b-button-group size="sm">
          <b-button variant="outline-secondary" :class="sortActive===0?'active':'none'" @click=draw_quantity()>
            Quantity
          </b-button>
          <b-button variant="outline-secondary" :class="sortActive===1?'active':'none'">Skill Similarity</b-button>
        </b-button-group>
      </div>
      <div id="line3">
        <h5>Salary shown by</h5>
        <b-button-group size="sm">
          <b-button variant="outline-secondary" :class="salaryActive===0?'active':'none'" @click="salaryActive=0">
            Location
          </b-button>
          <b-button variant="outline-secondary" :class="salaryActive===1?'active':'none'" @click="salaryActive=1">
            Qualification
          </b-button>
          <b-button variant="outline-secondary" :class="salaryActive===2?'active':'none'" @click="salaryActive=2">
            Experience
          </b-button>
          <b-button variant="outline-secondary" :class="salaryActive===3?'active':'none'" @click="salaryActive=3">
            Industry
          </b-button>
        </b-button-group>
        <div v-if="salaryActive===0">
          <b-dd text="Provinces" size="sm" variant="light">
            <b-dd-form style="overflow-y:scroll;overflow-x: hidden ; height:200px;">
              <b-dd-item-btn v-for="province in provinces" :key="province" @click=get_province_write_cities(province)>
                {{ province }}
              </b-dd-item-btn>
            </b-dd-form>
            <b-button @click=empty_cities() size="sm" variant="light" style="transform: translate(10px,5px)">Empty
            </b-button>
          </b-dd>
          <b-dd text="Cities" size="sm" variant="light">
            <b-dd-form style="overflow-y:scroll;overflow-x: hidden ; height:200px;">
              <b-form-checkbox-group v-model="selected_cities" :options="cities" stacked switches
                                     style="transform: translate(10px,0)"
                                     @change=limit_cities_num></b-form-checkbox-group>
            </b-dd-form>
            <b-button @click=draw_cities() size="sm" variant="light" style="transform: translate(10px,5px)">Confirm
            </b-button>
          </b-dd>
        </div>
      </div>
    </div>
    <div id="Overview_svg" @contextmenu.prevent></div>
  </div>
</template>

<script>
import * as d3 from 'd3'

import {getSkills, getPosts, getOtherInfo} from "@/common/data-service";
import {skill_link_color, bar_state, salary_limit} from "@/common/svg-help";//post_salary_link_color,
import {skill_text, skill_link, post_bar, skill_bar, skill_post_link, salary_bar} from "@/common/tools_for_d3";
import {
  get_skill_information, write_posts_list, get_posts_num_list,
  get_post_information, get_posts_similarity_list, translate_skill_name,
  translate_post_name, translate_posts_name_to_chinese, abbreviation_skill_name,
  get_salary_bar_length
} from "@/common/tools_for_data";
import PipeService from "@/common/pipe-service";

export default {
  name: "Overview",
  data() {
    return {
      cancelList: [],
      oldTime: 0,
      timeAwait: setTimeout(function () {
      }, 0),
      sortActive: 0,
      salaryActive: -1,
      value: ["test1", "test2", "test3", "test4"],
      value_test: "",
      posts_name: [],
      post_focus: "",
      posts_num_list: [],
      skills_model: {
        "Technical": {
          "Users and Organizations": ["Social Issues and Professional Practice"],
          "Systems Modeling": ["Data and Information Management"],
          "Sys. ARCH&Infrastructure": ["Virtual Systems and Services", "Intelligent Systems", "Parallel and Distributed Computing", "Computer Networks", "Embedded Systems", "Security Technology and Implementation"],
          "Software Development": ["Software Quality verification and validation", "Software Design", "Software Sustainment", "Platform-Based Development"],
          "Software Fundamentals": ["Graphics and Visualization", "Operating Systems", "Data Structures, Algorithms and Complexity", "Programming Languages"],
          "Hardware": ["Architecture and Organization", "Digital Design", "Circuits and Electronics", "Signal Processing"]
        },
        "Foundational": {
          "Personal": ["Mathematics and Statics", "Personal Skill", "Enterprising Skill"],
          "Interactive": ["Communication and Presentation", "Leadership Skill", "Interpersonal Skill", "Organisational Skill", "Team Skill"]
        }
      },
      chosen_text: {
        "Users and Organizations": 0, "Systems Modeling": 0, "Sys. ARCH&Infrastructure": 0,
        "Software Development": 0, "Software Fundamentals": 0, "Hardware": 0, "Personal": 0, "Interactive": 0
      },
      skills: {},
      posts: [],
      post_deleted: [],
      link1: {
        skill: {},
        post: {},
        linkList: []
      },
      posts_for_line3: [],
      other_info_focus: {},
      type: "locations",
      provinces: [],
      cities: [],
      selected_cities: [],
      type_list: ["locations", "qualifications", "experiences", "industries"],
      rank_list: [],
      data_for_job_info: {
        "post_id": [],
        "locations": [],
        "qualifications": [],
        "experiences": [],
        "industries": []
      }
    }
  },
  watch: {
    "salaryActive"(newVal) {
      if (newVal === 0) {
        this.get_other_info_draw_some("locations");
      } else if (newVal === 1) {
        this.get_other_info_draw_some("qualifications");
      } else if (newVal === 2) {
        this.get_other_info_draw_some("experiences");
      } else if (newVal === 3) {
        this.get_other_info_draw_some("industries");
      }
    }
  },
  methods: {
    delete_post() {
      let raw_posts = []
      for (const i in this.posts) {
        if (this.posts[i]["num"] < 100) {
          this.post_deleted.push(this.posts[i]["name"])
        } else {
          raw_posts.push(this.posts[i])
        }
      }
      for (const i in raw_posts) {
        let raw_similarity = {}
        for (const postName in raw_posts[i]["similarity"]) {
          if (!this.post_deleted.includes(postName)) {
            raw_similarity[postName] = raw_posts[i]["similarity"][postName]
          }
        }
        raw_posts[i]["similarity"] = raw_similarity
      }
      for (const skill of this.skills) {
        let raw_posts = []
        for (const post of skill["posts"]) {
          if (post[0] !== undefined && !this.post_deleted.includes(translate_posts_name_to_chinese([post[0]])[0])) {
            raw_posts.push(post)
          }
        }
        this.skills[this.skills.indexOf(skill)]["posts"] = raw_posts
      }
      this.posts = raw_posts
    },
    get_link1_skill_post(skills, posts) {
      for (const skill of skills) {
        this.link1["skill"][skill["name"]] = 0
      }
      for (const post of posts) {
        this.link1["post"][post["name"]] = 0
      }
    },
    draw_quantity() {
      this.sortActive = 0
      this.post_focus = ""
      this.draw_line2()
    },
    write_posts_for_line3() {
      this.posts_for_line3 = []
      for (const post_name in this.link1["post"]) {
        if (this.link1["post"][post_name] > 0) {
          this.posts_for_line3.push(post_name)
        }
      }
      for (const data_type in this.data_for_job_info) {
        this.data_for_job_info[data_type] = []
      }
      this.posts_for_line3 = translate_posts_name_to_chinese(this.posts_for_line3)
      let type = this.type
      this.other_info_focus = {}
      this.provinces = []
      this.cities = []
      this.selected_cities = []
      getOtherInfo(this.posts_for_line3, type, (res) => {
        this.other_info_focus = res.data
        let information = this.other_info_focus
        if (type !== "locations") {
          this.draw_line3(type)
        } else {
          let n = 0
          for (let province in information["Post amount"]) {
            this.provinces.push(province)
            for (let city in information["Post amount"][province]) {
              if (n < salary_limit - 4) {
                this.selected_cities.push(city)
                n += 1
              } else {
                break
              }
            }
          }
          this.salaryActive = 0
          this.draw_line3(type)
        }
      }, this.cancelList)
    },
    await_draw() {
      this.write_posts_for_line3()
      for (const index in this.cancelList) {
        if (index < this.cancelList.length - 1) {
          this.cancelList[index]()
        }
      }
      // let newTime=Date.now()
      // let that=this
      // if (newTime-that.oldTime>1000) {
      //   that.oldTime=newTime
      //   that.write_posts_for_line3()
      // } else {
      //   that.oldTime=newTime
      //   clearTimeout(that.timeAwait)
      //   that.timeAwait=setTimeout(function (){
      //     let newTime2=Date.now()
      //     if (newTime2-that.oldTime>1000) {
      //       that.oldTime=newTime2
      //       that.write_posts_for_line3()
      //     } else {
      //       that.await_draw()
      //     }
      //   },1000)
      // }
    },
    get_other_info_draw_some(type) {
      this.other_info_focus = {}
      this.provinces = []
      this.cities = []
      this.type = type
      getOtherInfo(this.posts_for_line3, type, (res) => {
        this.other_info_focus = res.data
        let information = this.other_info_focus
        if (type !== "locations") {
          this.draw_line3(type)
        } else {
          for (let province in information["Post amount"]) {
            this.provinces.push(province)
            this.draw_cities()
          }
        }
      }, this.cancelList)
    },

    get_province_write_cities(province) {
      this.cities = []
      let information = this.other_info_focus
      for (let city in information["Post amount"][province]) {
        this.cities.push({text: city, value: city})
      }
    },
    limit_cities_num() {
      if (this.selected_cities.length >= salary_limit) {
        document.querySelectorAll(".custom-control-input").forEach(input => {
          if (!input.checked) {
            input.disabled = true
          }
        })
      } else {
        document.querySelectorAll(".custom-control-input").forEach(input => {
          if (input.disabled) {
            input.disabled = false
          }
        })
      }
    },
    draw_cities() {
      this.draw_line3("locations")
    },
    empty_cities() {
      this.selected_cities = []
    },
    draw_svg_and_line1() {
      let skills = this.skills;
      let skills_model = this.skills_model;

      const width = 1200,
          height = 950;

      let skill_level1_y = 367.5, skill_level2_y = 105, skill_level3_y = 32;
      let skill_list_model = []
      let skill_list = []
      let chosen_text = this.chosen_text;

      //创建画布
      let svg = d3.select("#Overview_svg")
          .append("svg")
          .attr("width", width)
          .attr("height", height)
          .attr("id", "overview_svg")

      //绘制技能体系图表
      //：文字部分

      for (let skill_level1 in skills_model) {
        skill_text(svg, skill_level1, 5, skill_level1_y, 1)
        skill_level1_y += 420
        for (let skill_level2 in skills_model[skill_level1]) {
          skill_text(svg, skill_level2, 95, skill_level2_y, 2)
          skill_level2_y += 105
        }
      }

      for (let i = 0; i < skills.length; i++) {
        for (let skill of skills) {
          if (skill["sorted_num"] === i + 1) {
            skill_list_model.push({"name": skill["name"], "sorted_num": skill["sorted_num"]})
            break
          }
        }
      }
      for (let skill of skill_list_model) {
        for (let skill_level2 in skills_model["Technical"]) {
          for (let skill_test of skills_model["Technical"][skill_level2]) {
            if (skill["name"] === skill_test) {
              skill_list.push([skill_test, skill_level2, "Technical"])
            }
          }
        }
      }
      for (let skill of skill_list_model) {
        for (let skill_level2 in skills_model["Foundational"]) {
          for (let skill_test of skills_model["Foundational"][skill_level2]) {
            if (skill["name"] === skill_test) {
              skill_list.push([skill_test, skill_level2, "Foundational"])
            }
          }
        }
      }

      // 三级文本删除
      // for (let i in skill_list) {
      //   skill_text(svg,skill_list[i],275,skill_level3_y*(+i+1),3, this.skills)
      // }

      //：连线部分
      for (let skill_level1 in skills_model) {
        for (let skill_level2 in skills_model[skill_level1]) {
          skill_link(svg, skill_level1, skill_level2)

          // 三级连线删除
          // for (let skill_level3 of skills_model[skill_level1][skill_level2]) {
          //   skill_link(svg,skill_level2,skill_level3)
          // }

        }
      }
      // 为文本添加点击事件
      // 为一级文本添加连线颜色变化事件，二级文本事件发生变化<<
      document.querySelectorAll(".skill_text_1").forEach(text => {
        const className = ".skill_link_" + text.id.slice(11, text.id.length)
        text.setAttribute("name", "off")
        text.onclick = function () {
          if (text.getAttribute("name") === "off") {
            text.setAttribute("name", "on")
            d3.selectAll(className).attr("stroke", skill_link_color["on"])
            d3.selectAll(className).attr("stroke-width", "2px")
          } else {
            text.setAttribute("name", "off")
            d3.selectAll(className).attr("stroke", skill_link_color["off"])
            d3.selectAll(className).attr("stroke-width", "1px")
          }
        }
      })

      // 为二级文本添加生成三级文本的事件
      document.querySelectorAll(".skill_text_2").forEach(text => {
        // const className=".skill_link_"+text.id.slice(11,text.id.length)
        let that_prototype = this
        text.setAttribute("name", "off")
        text.onclick = function () {

          d3.selectAll(".skill_text_3").remove()
          for (let text2 in chosen_text) {
            if (chosen_text[text2] !== 0) {
              let className = ".skill_link_" + text2.replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_")
              d3.selectAll(className).remove()
            }
          }
          d3.selectAll(".skill_bar").remove()
          d3.selectAll(".skill_post_link").remove()
          d3.selectAll(".salary_bar").remove()
          d3.selectAll(".salary_name").remove()

          let text3_Technical_num = 0,
              text3_Foundational_num = 0;

          if (text.getAttribute("name") === "off") {
            text.setAttribute("name", "on")
            // d3.selectAll(className).attr("stroke",skill_link_color["on"])
            // d3.selectAll(className).attr("stroke-width","2px")
          } else {
            text.setAttribute("name", "off")

            // d3.selectAll(className).attr("stroke",skill_link_color["off"])
            // d3.selectAll(className).attr("stroke-width","1px")
          }

          for (let text2 in chosen_text) {
            if (text2.replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_") === text.id.slice(11, text.id.length)) {
              chosen_text[text2] = (chosen_text[text2] + 1) % 2

              if (chosen_text[text2] === 0) {
                for (const skill1 in that_prototype.skills_model) {
                  for (const skill2 in that_prototype.skills_model[skill1]) {
                    if (skill2 === text2) {
                      for (const skill3 of that_prototype.skills_model[skill1][text2]) {
                        for (const skill in that_prototype.link1["skill"]) {
                          if (skill === skill3) {
                            that_prototype.link1["skill"][skill] = 0
                            let delete_num = []
                            for (const link of that_prototype.link1["linkList"]) {
                              // console.log([link[0],skill])
                              // console.log(that_prototype.link1["linkList"])
                              if (link[0] === skill) {
                                that_prototype.link1["post"][link[1]] -= 1
                                delete_num.push(that_prototype.link1["linkList"].indexOf(link))
                              }
                            }
                            for (const num of delete_num) {
                              that_prototype.link1["linkList"].splice(num - delete_num.indexOf(num), 1)
                            }
                            break
                          }
                        }
                      }
                      break
                    }
                  }
                }
              }

            }
          }

          for (let skill_parent of skill_list) {
            if (chosen_text[skill_parent[1]] !== 0) {
              if (skill_parent[2] === "Technical") {
                skill_text(svg, skill_parent[0], 275, skill_level3_y * (+text3_Technical_num + 1), 3, this.skills)
                text3_Technical_num += 1
              } else {
                skill_text(svg, skill_parent[0], 275, skill_level3_y * (+text3_Foundational_num + 21), 3, this.skills)
                text3_Foundational_num += 1
              }

              skill_link(svg, skill_parent[1], skill_parent[0])
              for (let skill of skills) {
                // console.log(abbreviation_skill_name(skill_parent[0]))
                // console.log(skill_parent[0])
                // console.log(skill["name"])
                if (skill["name"] === skill_parent[0]) {
                  ``
                  skill_bar(svg, skill, 540)
                  break
                }
              }
            }
          }

          // for (let skill in that_prototype.link1["skill"]) {
          //   that_prototype.link1["skill"][skill]=0
          // }
          // for (let post in that_prototype.link1["post"]) {
          //   that_prototype.link1["post"][post]=0
          // }
          // that_prototype.link1['linkList']=[]

          document.querySelectorAll(".post_bar_back").forEach(bar => {
            // console.log("bar")
            // console.log(bar.id)
            if (bar.getAttribute("name") === "on") {
              bar.setAttribute("name", "off")
              d3.select("#" + bar.id).attr("stroke", bar_state["off"]["stroke"]).attr("stroke-width", bar_state["off"]["stroke-width"])
            }
          })

          document.querySelectorAll(".skill_bar").forEach(bar => {
            let that = that_prototype
            // id处理
            let id
            if (bar.id.split("_")[2] === "back") id = bar.id.slice(15, bar.id.length)
            else {
              if (bar.id.split("_")[2] === "proficiency") id = bar.id.slice(24, bar.id.length)
              else id = bar.id.slice(18, bar.id.length - 2)
            }
            let anchor_id = id

            //状态初始化
            document.querySelector("#skill_bar_back_" + anchor_id).setAttribute("name", "off")
            let check_dom = document.querySelector("#skill_bar_back_" + anchor_id)
            let check_d3 = d3.select("#skill_bar_back_" + anchor_id)

            bar.onclick = function () {
              if (check_dom.getAttribute("name") === "off") check_dom.setAttribute("name", "on")
              else check_dom.setAttribute("name", "off")

              if (check_dom.getAttribute("name") === "on") {
                check_d3.attr("stroke", bar_state["on"]["stroke"])
                check_d3.attr("stroke-width", bar_state["on"]["stroke-width"])

                let information = get_skill_information(anchor_id, skills)
                let max = information["posts"][0][1]
                for (let post of information["posts"]) {
                  if (post[0] !== undefined && post[1] / max >= 0.5) {
                    let flag = 1
                    for (const link of that.link1["linkList"]) {
                      if (link[0] === information["name"] && link[1] === post[0]) {
                        flag = 0
                        break
                      }
                    }
                    if (flag) {
                      that.link1["linkList"].push([information["name"], post[0], post[1] / max])
                      that.link1["skill"][information["name"]] += 1
                      that.link1["post"][post[0]] += 1
                      skill_post_link(svg, information["name"], post[0], post[1] / max)
                      let post_bar_back_id = "#post_bar_back_" + post[0].replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_").replace(/\+/g, "_")
                      let post_bar_back = document.querySelector(post_bar_back_id)
                      if (post_bar_back.getAttribute("name") === "off") {
                        post_bar_back.setAttribute("name", "on")
                        d3.select(post_bar_back_id).attr("stroke", bar_state["on"]["stroke"]).attr("stroke-width", bar_state["on"]["stroke-width"])
                      }
                    }
                  }
                }
              } else {
                let skill_id = anchor_id
                for (const skill_name in that.link1["skill"]) {
                  if (skill_id === skill_name.replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_")) {
                    that.link1["skill"][skill_name] = 0
                    break
                  }
                }
                document.querySelectorAll("." + anchor_id).forEach(link => {
                  let post_id = link.getAttribute("class").split(" ")[1]
                  for (const post_name in that.link1["post"]) {
                    if (post_id === post_name.replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_").replace(/\+/g, "_")) {
                      that.link1["post"][post_name] -= 1
                      for (const link of that.link1["linkList"]) {
                        if (anchor_id === link[0].replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_") && post_id === link[1].replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_").replace(/\+/g, "_")) {
                          that.link1["linkList"].splice(that.link1["linkList"].indexOf(link), 1)
                          break
                        }
                      }
                      break
                    }
                  }
                })
                for (const post_name in that.link1["post"]) {
                  if (that.link1["post"][post_name] === 0) {
                    let post_bar_back_id = "#post_bar_back_" + post_name.replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_").replace(/\+/g, "_")
                    let post_bar_back = document.querySelector(post_bar_back_id)
                    if (post_bar_back.getAttribute("name") === "on") {
                      post_bar_back.setAttribute("name", "off")
                      d3.select(post_bar_back_id).attr("stroke", bar_state["off"]["stroke"]).attr("stroke-width", bar_state["off"]["stroke-width"])
                    }
                  }
                }

                check_d3.attr("stroke", bar_state["off"]["stroke"])
                check_d3.attr("stroke-width", bar_state["off"]["stroke-width"])
                d3.selectAll("." + anchor_id).remove()
              }
              // console.log(that.link1)
              that.await_draw()
            }
          })

          //恢复选择状态
          // console.log(that_prototype.link1["skill"])
          document.querySelectorAll(".skill_bar_back").forEach(bar => {
            // console.log([bar.id])
            for (const skill in that_prototype.link1["skill"]) {
              if (that_prototype.link1["skill"][skill] !== 0 &&
                  bar.id.slice(15, bar.id.length) === skill.replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_")) {
                bar.setAttribute("name", "on")
                d3.select("#" + bar.id).attr("stroke", bar_state["on"]["stroke"]).attr("stroke-width", bar_state["on"]["stroke-width"])
                break
              }
            }
          })
          document.querySelectorAll(".post_bar_back").forEach(bar => {
            for (const post in that_prototype.link1["post"]) {
              if (that_prototype.link1["post"][post] !== 0 &&
                  bar.id.slice(14, bar.id.length) === post.replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_").replace(/\+/g, "_")) {
                bar.setAttribute("name", "on")
                d3.select("#" + bar.id).attr("stroke", bar_state["on"]["stroke"]).attr("stroke-width", bar_state["on"]["stroke-width"])
                break
              }
            }
          })
          for (const link of that_prototype.link1["linkList"]) {
            skill_post_link(svg, link[0], link[1], link[2])
          }

          that_prototype.await_draw()

        }
      })

      // 删除三级文本的技能条形图，技能条形图的生成方式发生了更改
      // //绘制技能条形图
      // for (let skill of skills) {
      //   skill_bar(svg,skill,540)
      // }

      //绑定collinear
      // document.querySelectorAll(".skill_text_3").forEach(text=>{
      //   let id=text.id.slice(11,text.id.length)
      //   text.onclick=function(){
      //     let information=get_skill_information(id,skills)
      //     skill_collinear(svg,information)
      //   }
      // })

      //绑定skill_post_link事件
      // document.querySelectorAll(".skill_bar").forEach(bar=>{
      //   let that=this
      //   //id处理
      //   let id
      //   if (bar.id.split("_")[2]==="back") id=bar.id.slice(15,bar.id.length)
      //   else id=bar.id.slice(18,bar.id.length-2)
      //   let anchor_id=id
      //
      //   //状态初始化
      //   document.querySelector("#skill_bar_back_"+anchor_id).setAttribute("name","off")
      //   let check_dom=document.querySelector("#skill_bar_back_"+anchor_id)
      //   let check_d3=d3.select("#skill_bar_back_"+anchor_id)
      //
      //   bar.onclick=function(){
      //     if (check_dom.getAttribute("name")==="off") check_dom.setAttribute("name","on")
      //     else check_dom.setAttribute("name","off")
      //
      //     if (check_dom.getAttribute("name")==="on"){
      //       check_d3.attr("stroke",bar_state["on"]["stroke"])
      //       check_d3.attr("stroke-width",bar_state["on"]["stroke-width"])
      //
      //       let information=get_skill_information(anchor_id,skills)
      //       let max=information["posts"][0][1]
      //       for (let post of information["posts"]) {
      //         if (post[0]!==undefined&&post[1]/max>=0.5) {
      //           let flag=1
      //           for (const link of that.link1["linkList"]) {
      //             if (link[0]===information["name"]&&link[1]===post[0]) {
      //               flag=0
      //               break
      //             }
      //           }
      //           if (flag) {
      //             that.link1["linkList"].push([information["name"],post[0],post[1]/max])
      //             that.link1["skill"][information["name"]]+=1
      //             that.link1["post"][post[0]]+=1
      //             skill_post_link(svg,information["name"],post[0],post[1]/max)
      //             let post_bar_back_id="#post_bar_back_"+post[0].replace(/\s/g,"_").replace(/&/g,"_").replace(/\./g,"_").replace(/\+/g,"_")
      //             let post_bar_back=document.querySelector(post_bar_back_id)
      //             if (post_bar_back.getAttribute("name")==="off") {
      //               post_bar_back.setAttribute("name","on")
      //               d3.select(post_bar_back_id).attr("stroke",bar_state["on"]["stroke"]).attr("stroke-width",bar_state["on"]["stroke-width"])
      //             }
      //           }
      //         }
      //       }
      //     }else {
      //       let skill_id=anchor_id
      //       for (const skill_name in that.link1["skill"]) {
      //         if (skill_id===skill_name.replace(/\s/g,"_").replace(/&/g,"_").replace(/\./g,"_")) {
      //           that.link1["skill"][skill_name]=0
      //           break
      //         }
      //       }
      //       document.querySelectorAll("."+anchor_id).forEach(link=>{
      //         let post_id=link.getAttribute("class").split(" ")[1]
      //         for (const post_name in that.link1["post"]) {
      //           if (post_id===post_name.replace(/\s/g,"_").replace(/&/g,"_").replace(/\./g,"_").replace(/\+/g,"_")) {
      //             that.link1["post"][post_name]-=1
      //             for (const link of that.link1["linkList"]) {
      //               if (anchor_id===link[0].replace(/\s/g,"_").replace(/&/g,"_").replace(/\./g,"_")&&post_id===link[1].replace(/\s/g,"_").replace(/&/g,"_").replace(/\./g,"_").replace(/\+/g,"_")) {
      //                 that.link1["linkList"].splice(that.link1["linkList"].indexOf(link),1)
      //                 break
      //               }
      //             }
      //             break
      //           }
      //         }
      //       })
      //       for (const post_name in that.link1["post"]) {
      //         if (that.link1["post"][post_name]===0) {
      //           let post_bar_back_id="#post_bar_back_"+post_name.replace(/\s/g,"_").replace(/&/g,"_").replace(/\./g,"_").replace(/\+/g,"_")
      //           let post_bar_back=document.querySelector(post_bar_back_id)
      //           if (post_bar_back.getAttribute("name")==="on") {
      //             post_bar_back.setAttribute("name","off")
      //             d3.select(post_bar_back_id).attr("stroke",bar_state["off"]["stroke"]).attr("stroke-width",bar_state["off"]["stroke-width"])
      //           }
      //         }
      //       }
      //
      //       check_d3.attr("stroke",bar_state["off"]["stroke"])
      //       check_d3.attr("stroke-width",bar_state["off"]["stroke-width"])
      //       d3.selectAll("."+anchor_id).remove()
      //     }
      //     that.write_posts_for_line3()
      //   }
      // })
    },
    draw_line2() {
      let draw_line2 = this.draw_line2

      let posts = this.posts
      let svg = d3.select("#overview_svg")

      let post_focus = this.post_focus
      let posts_name = this.posts_name
      let posts_num_list = this.posts_num_list

      //绘制post_bar
      if (post_focus !== "") {
        d3.selectAll(".post_bar").remove()
        d3.selectAll(".post_name").remove()
        let focus_information = get_post_information(post_focus, posts)
        let posts_similarity_list = get_posts_similarity_list(focus_information)
        for (let i in posts_similarity_list) {
          posts_similarity_list[i] = translate_post_name(posts_similarity_list[i])
        }
        for (let i in posts_similarity_list) {
          let information = get_post_information(posts_similarity_list[i], posts)
          post_bar(svg, information, 600, 22 + 886 * (+i) / (posts_similarity_list.length))
        }
      } else {
        d3.selectAll(".post_bar").remove()
        d3.selectAll(".post_name").remove()
        for (let i in posts_num_list) {
          let information = get_post_information(posts_num_list[i], posts)
          post_bar(svg, information, 600, 22 + 886 * (+i) / (posts_num_list.length))
        }
      }

      //绑定similarity绘制事件
      document.querySelectorAll(".post_bar").forEach(post => {
        //id处理
        let id
        if (post.id.split("_")[1] === "bar") id = post.id.slice(14, post.id.length)
        else if (post.id.split("_")[1] === "technical") id = post.id.slice(19, post.id.length)
        else id = post.id.slice(22, post.id.length)
        let anchor_id = id
        let that = this
        post.oncontextmenu = function () {
          that.sortActive = 1
          for (let name of posts_name) {
            if (name.replace(/\s/g, "_").replace(/\./g, "_").replace(/\+/g, "_").replace(/&/g, "_") === anchor_id) {
              that.post_focus = name
              break
            }
          }
          draw_line2()
        }
      })

      //绑定skill_post_link事件
      document.querySelectorAll(".post_bar").forEach(post => {
        let that = this
        //id处理
        let id
        if (post.id.split("_")[1] === "bar") id = post.id.slice(14, post.id.length)
        else if (post.id.split("_")[1] === "technical") id = post.id.slice(19, post.id.length)
        else id = post.id.slice(22, post.id.length)
        let anchor_id = id

        //状态初始化
        document.querySelector("#post_bar_back_" + anchor_id).setAttribute("name", "off")
        let check_dom = document.querySelector("#post_bar_back_" + anchor_id)
        let check_d3 = d3.select("#post_bar_back_" + anchor_id)

        post.onclick = function () {
          if (check_dom.getAttribute("name") === "off") check_dom.setAttribute("name", "on")
          else check_dom.setAttribute("name", "off")

          if (check_dom.getAttribute("name") === "on") {
            check_d3.attr("stroke", bar_state["on"]["stroke"])
            check_d3.attr("stroke-width", bar_state["on"]["stroke-width"])

            let information = []
            for (let post of posts) {
              if (post["name"].replace(/\s/g, "_").replace(/\./g, "_").replace(/\+/g, "_").replace(/&/g, "_") === id) {
                information = post
                break
              }
            }
            let max = 0
            for (let skill_type in information["skills"]) {
              for (let skill in information["skills"][skill_type]) {
                if (translate_skill_name(skill) && max < information["skills"][skill_type][skill]) max = information["skills"][skill_type][skill]
              }
            }
            console.log('max', max, information['skills'])
            console.log('link', that.link1)
            for (let skill_type in information["skills"]) {
              for (let skill in information["skills"][skill_type]) {
                if (translate_skill_name(skill) && information["skills"][skill_type][skill] / max >= 0.1) {
                  let flag = 1
                  for (const link of that.link1["linkList"]) {
                    if (link[1] === information["name"] && link[0] === abbreviation_skill_name(translate_skill_name(skill))) {
                      flag = 0
                      break
                    }
                  }
                  if (that.link1["skill"][abbreviation_skill_name(translate_skill_name(skill))] === 0) {
                    flag = 0
                    that.link1["post"][information["name"]] += 1
                  }
                  if (flag) {
                    that.link1["linkList"].push([abbreviation_skill_name(translate_skill_name(skill)), information["name"], information["skills"][skill_type][skill] / max])
                    that.link1["skill"][abbreviation_skill_name(translate_skill_name(skill))] += 1
                    that.link1["post"][information["name"]] += 1
                    skill_post_link(svg, abbreviation_skill_name(translate_skill_name(skill)), information["name"], information["skills"][skill_type][skill] / max)
                    let skill_bar_back_id = "#skill_bar_back_" + abbreviation_skill_name(translate_skill_name(skill)).replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_")
                    let skill_bar_back = document.querySelector(skill_bar_back_id)
                    if (skill_bar_back.getAttribute("name") === "off") {
                      skill_bar_back.setAttribute("name", "on")
                      d3.select(skill_bar_back_id).attr("stroke", bar_state["on"]["stroke"]).attr("stroke-width", bar_state["on"]["stroke-width"])
                    }
                  }
                }
              }
            }
          } else {
            check_d3.attr("stroke", bar_state["off"]["stroke"])
            check_d3.attr("stroke-width", bar_state["off"]["stroke-width"])

            let post_id = anchor_id
            for (const post_name in that.link1["post"]) {
              if (post_id === post_name.replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_").replace(/\+/g, "_")) {
                that.link1["post"][post_name] = 0
                break
              }
            }
            document.querySelectorAll("." + anchor_id).forEach(link => {
              let skill_id = link.getAttribute("class").split(" ")[0]
              for (const skill_name in that.link1["skill"]) {
                if (skill_id === skill_name.replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_")) {
                  that.link1["skill"][skill_name] -= 1
                  for (const link of that.link1["linkList"]) {
                    if (skill_id === link[0].replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_") && anchor_id === link[1].replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_").replace(/\+/g, "_")) {
                      that.link1["linkList"].splice(that.link1["linkList"].indexOf(link), 1)
                      break
                    }
                  }
                  break
                }
              }
            })
            for (const skill_name in that.link1["skill"]) {
              if (that.link1["skill"][skill_name] === 0) {
                let skill_bar_back_id = "#skill_bar_back_" + skill_name.replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_")
                if (document.querySelector(skill_bar_back_id) !== null) {
                  let skill_bar_back = document.querySelector(skill_bar_back_id)
                  if (skill_bar_back.getAttribute("name") === "on") {
                    skill_bar_back.setAttribute("name", "off")
                    d3.select(skill_bar_back_id).attr("stroke", bar_state["off"]["stroke"]).attr("stroke-width", bar_state["off"]["stroke-width"])
                  }
                }
              }
            }

            check_d3.attr("stroke", bar_state["off"]["stroke"])
            check_d3.attr("stroke-width", bar_state["off"]["stroke-width"])
            d3.selectAll("." + id).remove()
          }
          that.await_draw()
        }
      })

      //绘制skill_post_link并且，显示选中状态
      d3.selectAll(".skill_post_link").remove()
      d3.selectAll(".post_salary_link").remove()
      for (const link of this.link1["linkList"]) {
        skill_post_link(svg, link[0], link[1], link[2])
      }
      for (const link_post in this.link1["post"]) {
        if (this.link1["post"][link_post] > 0) {
          let link_post_id = link_post.replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_").replace(/\+/g, "_")
          document.querySelector("#post_bar_back_" + link_post_id).setAttribute("name", "on")
          d3.select("#post_bar_back_" + link_post_id).attr("stroke", bar_state["on"]["stroke"]).attr("stroke-width", bar_state["on"]["stroke-width"])
          // let post_text_id="#post_name_of_"+link_post_id
          // document.querySelectorAll(".salary_bar_back").forEach(back=>{
          //   let data={
          //     source:{
          //       x:parseFloat(d3.select(post_text_id).attr("x"))+3,
          //       y:parseFloat(d3.select(post_text_id).attr("y"))-3
          //     },
          //     target:{
          //       x:parseFloat(back.getAttribute("x")),
          //       y:parseFloat(back.getAttribute("y"))+5
          //     }
          //   }
          //   let link=d3.linkHorizontal().x(d=>d.x).y(d=>d.y)
          //   svg.append("path")
          //       .attr("d",link(data))
          //       .attr("fill","none")
          //       .style("stroke",post_salary_link_color)
          //       .style("stroke-width",0.5)
          //       .attr("class","post_salary_link")
          // })
        }
      }

    },
    draw_line3(type) {
      d3.selectAll(".salary_bar,.salary_name,.post_salary_link").remove()
      let svg = d3.select("#overview_svg")
      let information = this.other_info_focus
      let cities = this.selected_cities

      if (type !== "locations") {
        let max_length = 0
        let max_num = 0
        this.rank_list = []
        for (let name in information["Post amount"]) {
          if (information["Salary distribution"][name] !== undefined) {
            let num = information["Post amount"][name]
            if (max_length < name.length) max_length = name.length
            if (max_num < num) max_num = num
            this.rank_list.push([name, num])
          }
        }
        this.rank_list.sort((a, b) => b[1] - a[1])
        let max_bar_length = 250 - 11 * max_length - 10

        let t = 1
        for (const ele of this.rank_list) {
          for (let name in information["Post amount"]) {
            if (information["Salary distribution"][name] !== undefined && name === ele[0]) {
              let num = information["Post amount"][name]
              let salary_info = information["Salary distribution"][name]
              salary_bar(svg, name, max_bar_length * get_salary_bar_length(num) / get_salary_bar_length(max_num), salary_info, 910, 27 * (t - 1) + 22, t)
              t += 1
            }
          }
        }

      } else {
        let max_length = 0
        let max_num = 0
        this.rank_list = []
        for (let city_selected of cities) {
          for (let province in information["Post amount"]) {
            for (let city in information["Post amount"][province]) {
              if (city === city_selected) {
                let name = city
                let num = information["Post amount"][province][city]
                if (max_length < name.length) max_length = name.length
                if (max_num < num) max_num = num
                this.rank_list.push([name, num])
              }
            }
          }
        }
        this.rank_list.sort((a, b) => b[1] - a[1])
        let max_bar_length = 250 - 11 * max_length - 10

        let t = 1
        for (const ele of this.rank_list) {
          for (let city_selected of cities) {
            for (let province in information["Post amount"]) {
              for (let city in information["Post amount"][province]) {
                if (city === city_selected && city === ele[0]) {
                  let name = city
                  let num = information["Post amount"][province][city]
                  let salary_info = information["Salary distribution"][province][city]
                  salary_bar(svg, name, max_bar_length * get_salary_bar_length(num) / get_salary_bar_length(max_num), salary_info, 910, 27 * (t - 1) + 22, t)
                  t += 1
                }
              }
            }
          }
        }

      }

      //绑定点击事件，记录传输的数据
      document.querySelectorAll(".salary_bar").forEach(bar => {
        let that = this
        //id处理
        let back_id = bar.id.split("_")[3]
        let anchor_id = back_id

        //状态初始化
        document.querySelector("#salary_bar_back_" + anchor_id).setAttribute("name", "off")
        let check_dom = document.querySelector("#salary_bar_back_" + anchor_id)
        let check_d3 = d3.select("#salary_bar_back_" + anchor_id)

        //刷新后恢复点击状态
        for (const register of this.data_for_job_info[type]) {
          for (const rank of this.rank_list) {
            if (register === rank[0] && parseInt(anchor_id) - 1 === this.rank_list.indexOf(rank)) {
              check_dom.setAttribute("name", "on")
              check_d3.attr("stroke", bar_state["on"]["stroke"])
              check_d3.attr("stroke-width", bar_state["on"]["stroke-width"])
            }
          }
        }

        bar.onclick = function () {
          if (check_dom.getAttribute("name") === "off") check_dom.setAttribute("name", "on")
          else check_dom.setAttribute("name", "off")

          that.data_for_job_info["post_id"] = that.posts_for_line3

          if (check_dom.getAttribute("name") === "on") {
            check_d3.attr("stroke", bar_state["on"]["stroke"])
            check_d3.attr("stroke-width", bar_state["on"]["stroke-width"])

            that.data_for_job_info[type].push(that.rank_list[parseInt(back_id) - 1][0])

          } else {
            check_d3.attr("stroke", bar_state["off"]["stroke"])
            check_d3.attr("stroke-width", bar_state["off"]["stroke-width"])

            that.data_for_job_info[type].splice(that.data_for_job_info[type].indexOf(that.rank_list[parseInt(back_id) - 1][0]), 1)

          }

          PipeService.$emit("data_to_middle", that.data_for_job_info)
        }
      })
    }
  },
  mounted() {
    Promise.all([
      new Promise((resolve) => {
        getSkills((res) => {
          for (let skill1 in this.skills_model) {
            for (let skill2 in this.skills_model[skill1]) {
              for (let i in this.skills_model[skill1][skill2]) {
                this.skills_model[skill1][skill2][i] = abbreviation_skill_name(this.skills_model[skill1][skill2][i])
              }
            }
          }
          this.skills = res.data
          return resolve('success')
        })
      }),
      new Promise((resolve) => {
        getPosts((res) => {
          this.posts = res.data

          return resolve('success')
        })
      })]).then((resolve) => {
      for (let skill of this.skills) {
        skill["name"] = abbreviation_skill_name(translate_skill_name(skill["name"]))
        for (let skill_name of skill["cooccurrence"]) {
          skill_name[0] = abbreviation_skill_name(translate_skill_name(skill_name[0]))
        }
        for (let i in skill["posts"]) {
          skill["posts"][i][0] = translate_post_name(skill["posts"][i][0])
        }
      }

      this.delete_post()
      for (let post of this.posts) {
        post["name"] = translate_post_name(post["name"])
      }

      console.log(resolve)
      this.posts_name = write_posts_list(this.posts)
      this.posts_num_list = get_posts_num_list(this.posts)
      this.get_link1_skill_post(this.skills, this.posts)
      this.draw_svg_and_line1();
      this.draw_line2()
    }).catch((error) => {
      console.log(error)
    })

  }
}
</script>

<style scoped>

#Overview {
  position: absolute;
  left: 10px;
  top: 70px;
  width: 1200px;
  height: 1050px;
  border: 2px solid rgba(255, 255, 255, 0);
  border-radius: 5px;
  background-color: #ffffff;
  cursor: default;
}

#overview-legend {
  position: absolute;
  bottom: 50px;
  right: 35px;

  width: 250px;
  height: 225px
}

#title {
  height: 50px;
  width: 1200px;
}

#button_box {
  height: 50px;
  width: 100%;
}

#line1 {
  height: 50px;
  width: 700px;
  float: left;
}

#line2 {
  position: absolute;
  float: left;
  display: flex;
  top: 50px;
  left: 10px;
}

#line2 h5 {
  margin-top: 8px;
  margin-right: 10px;
  margin-bottom: 0;
}

#line3 {
  position: absolute;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 10px;
  right: 20px;

}

#Overview_svg {
  height: 950px;
  width: 1200px;
}

/*.relevance{*/
/*  width: 200px;*/
/*  height: 200px;*/
/*  overflow: scroll;*/
/*}*/

</style>
