// import {initInterpolator} from "d3-scale/src/init";

import {ClusterSvg, radarColor} from "@/common/svg-help";

export function get_skill_information(id, skills) {
    for (let skill of skills) {
        if (skill["name"].replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_") === id) {
            return skill
        }
    }
}

export function get_skill_bar_length(num) {
    let skill_bar_length
    if (num < 8000) {
        skill_bar_length = 60 * num / 8000
    } else if (num < 80000) {
        skill_bar_length = 60 + 15 * (num - 8000) / (80000 - 8000)
    } else {
        skill_bar_length = 75 + 25 * (num - 80000) / (800000 - 80000)
    }
    return skill_bar_length * 1.5
}

export function write_posts_list(posts) {
    let posts_list = []
    for (let post of posts) {
        posts_list.push(post["name"])
    }
    return posts_list
}

export function get_posts_num_list(posts) {
    let num_list_arr = []
    for (let post of posts) {
        num_list_arr.push([post["name"], post["num"]])
    }
    num_list_arr.sort((a, b) => {
        return b[1] - a[1]
    })
    let num_list = []
    for (let el of num_list_arr) {
        num_list.push(el[0])
    }
    return num_list
}

export function get_posts_similarity_list(information) {
    let similarity_list_arr = []
    for (let post in information["similarity"]) {
        similarity_list_arr.push([post, information["similarity"][post]])//不清楚具体的similarity接口的样子，这只是一个模拟，需要更改
    }
    similarity_list_arr.sort((a, b) => {
        return b[1] - a[1]
    })
    let similarity_list = []
    for (let el of similarity_list_arr) {
        similarity_list.push(el[0])
    }
    return similarity_list
}

export function get_post_information(post_name, posts) {
    let post_information = {}
    for (let post of posts) {
        if (post["name"] == post_name) {
            post_information = post
            continue
        }
    }
    return post_information
}

export function get_post_bar_length(num) {
    let post_bar_length
    if (num <= 10) {
        post_bar_length = 10 + num
    } else if (num <= 110) {
        post_bar_length = 20 + 20 * (num - 10) / 100
    } else if (num < 1110) {
        post_bar_length = 40 + 20 * (num - 110) / 1000
    } else if (num < 11110) {
        post_bar_length = 60 + 20 * (num - 1110) / 10000
    } else {
        post_bar_length = 80 + 20 * (num - 11110) / 40000
    }
    return post_bar_length * 1.5
}

export function get_salary_bar_length(num) {
    let salary_length
    if (num <= 10) {
        salary_length = 20 + num
    } else if (num <= 100) {
        salary_length = 30 + (num - 10) / 9
    } else if (num <= 1000) {
        salary_length = 40 + (num - 100) / 90
    } else if (num <= 2000) {
        salary_length = 50 + (num - 1000) / 100
    } else if (num <= 5000) {
        salary_length = 60 + (num - 2000) / 300
    } else if (num <= 10000) {
        salary_length = 70 + (num - 5000) / 500
    } else if (num <= 20000) {
        salary_length = 80 + (num - 10000) / 1000
    } else {
        salary_length = 90 + (num - 20000) / 2000
    }
    return salary_length * 1.5
}

export function get_text_length(text) {
    let letter_length = {
        "a": 5.3,
        "b": 6,
        "c": 4.8,
        "d": 6,
        "e": 5.5,
        "f": 3.1,
        "g": 6,
        "h": 5.8,
        "i": 2.2,
        "j": 2.3,
        "k": 4.8,
        "l": 2.2,
        "m": 8.9,
        "n": 5.8,
        "o": 6,
        "p": 6,
        "q": 6,
        "r": 3.6,
        "s": 4.2,
        "t": 3.3,
        "u": 5.8,
        "v": 5,
        "w": 7.4,
        "x": 4.6,
        "y": 5,
        "z": 5,
        "A": 6.8,
        "B": 5.9,
        "C": 6.7,
        "D": 7.3,
        "E": 5.4,
        "F": 5,
        "G": 7.2,
        "H": 7.3,
        "I": 2.5,
        "J": 3.6,
        "K": 5.7,
        "L": 4.9,
        "M": 9,
        "N": 7.7,
        "O": 8.2,
        "P": 5.9,
        "Q": 8.2,
        "R": 6,
        "S": 5.4,
        "T": 5.5,
        "U": 7,
        "V": 6.6,
        "W": 9.7,
        "X": 6.1,
        "Y": 5.8,
        "Z": 6.2,
        "1": 3.85,
        "2": 5.58,
        "3": 5.56,
        "4": 5.8,
        "5": 5.58,
        "6": 5.58,
        "7": 5.42,
        "8": 5.58,
        "9": 5.58,
        "0": 5.58,
        "+": 7,
        "&": 7.5,
        ".": 2.4,
        " ": 2.9
    }
    let text_length = 0
    for (const letter of text) {
        text_length += parseFloat(letter_length[letter])
    }
    return text_length * 1.2
}

export function get_radar_r(length, width, radarNum) {
    let n = 0, limit = 30
    do {
        n += 0.1
    } while ((n + 1) * (n + 1) * 4 * radarNum * 3 < length * width && n * 2 < width && n * 2 < length)
    n -= 0.1
    // console.log('y',y,'r',n,'if',(y*2*(n+5)<=width))
    // console.log('function!')
    if (n > limit) n = limit
    if (n > 10 && radarNum > 40) {
        n -= 5
    }
    return n
}

export function get_radar_axis(num, r, length, width, startX, startY) {
    let axisBox, victory
    do {
        victory = 0
        axisBox = []
        for (let i = 0; i < num; i++) {
            let x, y, flag, time = 0
            do {
                flag = 0
                time += 1
                x = startX + (r + 1) + Math.ceil(Math.random() * (length - 2 * (r + 1)))
                y = startY + (r + 1) + Math.ceil(Math.random() * (width - 2 * (r + 1)))
                for (const axis of axisBox) {
                    if (Math.sqrt(Math.pow(x - axis[0], 2) + Math.pow(y - axis[1], 2)) < 2 * (r + 1)) {
                        flag = 1
                        break
                    }
                }
                if (time >= 10000) {
                    flag = 0
                    break
                }
            } while (flag)
            axisBox.push([x, y])
            if (time >= 10000) {
                victory = 1
                break
            }
        }
    } while (victory)

    return axisBox
}

export function collision_detection(axisBox, r) {
    let top = 0,
        bottom = (ClusterSvg['height'] - ClusterSvg['rect1']),
        left = 0, right = ClusterSvg['width']
    let newAxisBox = []
    let space = 5
    let tag
    let xMax = 0, yMax = 0

    newAxisBox = axisBox

    //初始化
    for (const axis of newAxisBox) {
        xMax = (xMax < Math.abs(axis['x'])) ? Math.abs(axis['x']) : xMax
        yMax = (yMax < Math.abs(axis['y'])) ? Math.abs(axis['y']) : yMax
    }
    for (const axis of newAxisBox) {
        axis['x'] *= right / xMax
        axis['y'] *= bottom / yMax
    }
    for (const axis of newAxisBox) {
        // axis['x']-=left
        // axis['y']-=top
        if (axis['x'] < left + r + space) {
            axis['x'] = left + r + space + (Math.random() - 0.5)
        }
        if (axis['x'] > right - r - space) {
            axis['x'] = right - r - space + (Math.random() - 0.5)
        }
        if (axis['y'] < top + r + space) {
            axis['y'] = top + r + space + (Math.random() - 0.5)
        }
        if (axis['y'] > bottom - r - space) {
            axis['y'] = bottom - r - space + (Math.random() - 0.5)
        }
    }
    //扩散
    do {
        tag = false
        //扩散
        for (let i = 0; i < newAxisBox.length; i++) {
            for (let j = i + 1; j < newAxisBox.length; j++) {
                let d = Math.sqrt(Math.pow(newAxisBox[i]['x'] - newAxisBox[j]['x'], 2) +
                    Math.pow(newAxisBox[i]['y'] - newAxisBox[j]['y'], 2))
                if (d < 2 * r + space) {
                    let dx = (2 * r + space) * (newAxisBox[i]['x'] - newAxisBox[j]['x']) / d,
                        dy = (2 * r + space) * (newAxisBox[i]['y'] - newAxisBox[j]['y']) / d,
                        tw = newAxisBox[i]['num'] + newAxisBox[j]['num'],
                        flag = false
                    tag = true

                    newAxisBox[i]['x'] += dx * newAxisBox[j]['num'] / tw + (Math.random() - 0.5)
                    newAxisBox[j]['x'] -= dx * newAxisBox[i]['num'] / tw + (Math.random() - 0.5)
                    newAxisBox[i]['y'] += dy * newAxisBox[j]['num'] / tw + (Math.random() - 0.5)
                    newAxisBox[j]['y'] -= dy * newAxisBox[i]['num'] / tw + (Math.random() - 0.5)
                    if (newAxisBox[i]['x'] < left + r + space) {
                        newAxisBox[i]['x'] = left + r + space + (Math.random() - 0.5)
                        flag = true
                    }
                    if (newAxisBox[i]['x'] > right - r - space) {
                        newAxisBox[i]['x'] = right - r - space + (Math.random() - 0.5)
                        flag = true
                    }
                    if (flag) {
                        newAxisBox[i]['y'] += (Math.random() - 0.5)
                        flag = false
                    }
                    if (newAxisBox[i]['y'] < top + r + space) {
                        newAxisBox[i]['y'] = top + r + space + (Math.random() - 0.5)
                        flag = true
                    }
                    if (newAxisBox[i]['y'] > bottom - r - space) {
                        newAxisBox[i]['y'] = bottom - r - space + (Math.random() - 0.5)
                        flag = true
                    }
                    if (flag) {
                        newAxisBox[i]['x'] += (Math.random() - 0.5)
                        flag = false
                    }

                    if (newAxisBox[j]['x'] < left + r + space) {
                        newAxisBox[j]['x'] = left + r + space + (Math.random() - 0.5)
                        flag = true
                    }
                    if (newAxisBox[j]['x'] > right - r - space) {
                        newAxisBox[j]['x'] = right - r - space + (Math.random() - 0.5)
                        flag = true
                    }
                    if (flag) {
                        newAxisBox[j]['y'] += (Math.random() - 0.5)
                        flag = false
                    }
                    if (newAxisBox[j]['y'] < top + r + space) {
                        newAxisBox[j]['y'] = top + r + space + (Math.random() - 0.5)
                        flag = true
                    }
                    if (newAxisBox[j]['y'] > bottom - r - space) {
                        newAxisBox[j]['y'] = bottom - r - space + (Math.random() - 0.5)
                        flag = true
                    }
                    if (flag) {
                        newAxisBox[j]['x'] += (Math.random() - 0.5)
                        flag = false
                    }
                }
            }
        }
    } while (tag)

    let resBox = []
    for (const axis of newAxisBox) {
        resBox.push([axis['x'] - left, axis['y'] - top])
    }
    return resBox
}


export function translate_skill_name(skill_name) {
    let skill_list = {
        "软件设计": "Software Design",
        "软件维护": "Software Sustainment",
        "基于平台的软件开发": "Platform-Based Development",
        "社会问题和实践": "Social Issues and Professional Practice",
        "数据和信息管理": "Data and Information Management",
        "虚拟系统与服务": "Virtual Systems and Services",
        "AI": "Intelligent Systems",
        "并行与分布式": "Parallel and Distributed Computing",
        "计算机网络": "Computer Networks",
        "嵌入式系统": "Embedded Systems",
        "安全技术与应用": "Security Technology and Implementation",
        "软件测试与验证": "Software Quality verification and validation",
        "图形与可视化": "Graphics and Visualization",
        "操作系统": "Operating Systems",
        "数据结构与算法": "Data Structures, Algorithms and Complexity",
        "编程语言": "Programming Languages",
        "计算机架构与组织": "Architecture and Organization",
        "数字逻辑": "Digital Design",
        "电子电路": "Circuits and Electronics",
        "信号处理": "Signal Processing",
        "数学和统计": "Mathematics and Statics",
        "其他个人素质": "Personal Skill",
        "创新能力": "Enterprising Skill",
        "交流与表达": "Communication and Presentation",
        "领导与组织能力": "Leadership Skill",
        "人际交往能力": "Interpersonal Skill",
        "项目管理": "Organisational Skill",
        "团队合作": "Team Skill"
    }
    return skill_list[skill_name]
}

export function translate_post_name(post_name) {
    let post_list = {
        "软件工程师": "Software ENG.",
        "测试工程师": "Test ENG.",
        "运维工程师": "Operations ENG.",
        "Java开发工程师": "Java Dev",
        "算法工程师": "Algorithmic ENG.",
        "Web前端开发": "Frontend Dev",
        "软件测试工程师": "Software Test",
        "系统工程师": "Systems ENG.",
        "Android开发工程师": "Android Dev",
        "PHP开发工程师": "PHP Dev",
        "NET开发工程师": ".NET Dev",
        "iOS开发工程师": "IOS Dev",
        "大数据开发工程师": "Big Data Dev",
        "高级软件工程师": "Senior Software ENG.",
        "嵌入式软件开发": "Embedded Software ENG.",
        "C开发工程师": "C Dev",
        "图像算法工程师": "Image Alg. ENG.",
        "Python开发工程师": "Python Dev",
        "测试开发": "Test Dev",
        "系统测试": "System Test",
        "自动化测试": "Auto Test",
        "运维开发": "Operation&Maintenance",
        "Unity3d开发工程师": "Unity3d Dev",
        "ETL开发工程师": "ETL Dev",
        "游戏开发工程师": "Game Dev",
        "游戏测试": "Game Test",
        "机器视觉工程师": "Machine Vision ENG.",
        "C++开发工程师": "C++ Dev",
        "HTML5开发工程师": "HTML5 Dev",
        "性能测试": "Performance Test",
        "小程序开发工程师": "Mini-program Dev",
        "项目经理": "PM",
        "功能测试": "Func Test",
        "移动开发工程师": "Mobile Dev",
        "爬虫开发工程师": "Crawler Dev",
        "图像处理工程师": "Image processing ENG.",
        "区块链开发": "Blockchain Dev",
        "UE4开发工程师": "UE4 Dev",
        "安全测试": "Safety Test",
        "机器学习工程师": "ML ENG.",
        "Cocos2dx开发工程师": "Cocos2dx Dev",
        "游戏客户端开发工程师": "Game client Dev",
        "深度学习工程师": "DL ENG.",
        "系统架构设计师": "System ARCH designer",
        "全栈工程师": "Full Stack ENG.",
        "电子软件开发": "Electronic software Dev",
        "游戏服务端开发工程师": "Game server Dev",
        "系统分析员": "System Analyst",
        "多媒体开发工程师": "Multimedia Dev",
        "数据分析师": "Data Analyst",
        "ERP技术开发": "ERP Dev",
        "脚本开发工程师": "Script Dev",
        "图像识别工程师": "Image Rec.",
        "Go开发工程师": "Go Dev",
        "Ruby开发工程师": "Ruby Dev",
        "测试主管": "Test Supervisor",
        "语音识别工程师": "Speech Rec.",
        "移动端测试": "Mobile terminal Test",
        "项目主管": "Project director",
        "测试经理": "Test Manager",
        "数据库工程师DBA": "DBA",
        "项目助理": "Project Assistant",
        "系统集成工程师": "System Integration ENG.",
        "数据采集工程师": "Data Acquisition ENG.",
        "BI工程师": "BI ENG.",
        "网络安全工程师": "Network Security ENG.",
        "自然语言处理NLP": "NLP",
        "Hadoop工程师": "Hadoop ENG.",
        "项目总监": "Project Director",
        "IT经理": "IT manager",
        "数据标注师": "Data annotator",
        "测试总监": "Test Director",
        "网络工程师IT工程师": "IT ENG.",
        "GIS工程师": "GIS ENG.",
        "ERP实施顾问": "ERP Consultant",
        "电子技术研发工程师": "Electronic Tech. RD",
        "数据建模工程师": "Data Modeling ENG.",
        "游戏运营": "Game Operation",
        "标准化工程师": "Standardization ENG.",
        "网站维护工程师": "Website maintenance",
        "技术总监": "Technical Director"
    }
    return post_list[post_name]
}

export function translateCompanySize(label) {
    let companySize = {
        "少于50人": {"height": 50, "content": "Less than 50 people"},
        "50-150人": {"height": 110, "content": "50-150 persons"},
        "150-500人": {"height": 190, "content": "150-500 persons"},
        "500-1000人": {"height": 250, "content": "500-1000 persons"},
        "1000-5000人": {"height": 300, "content": "1000-5000 persons"},
        "5000-10000人": {"height": 450, "content": "5000-10000 persons"},
        "10000人以上": {"height": 500, "content": "More than 10000 people"}
    };

    return companySize[label]
}

export function translateCompanyCategory(label) {
    let companyCategory = {
        "国企": "State-owned Enterprise",
        "外资（欧美）": "Foreign investment (Europe and America)",
        "外资（非欧美）": "Foreign investment (non European and American)",
        "上市公司": "Listed Company",
        "合资": "Joint Investment",
        "民营公司": "Private company",
        "外企代表处": "Foreign enterprise representative office",
        "政府机关": "Government Office",
        "事业机关": "Public Institution",
        "非营利组织": "Non-profit Organization",
        "创业公司": "Start-up company"
    }

    return companyCategory[label]
}

export function translate_posts_name_to_chinese(posts_name) {
    let post_list_en = {
        "软件工程师": "Software ENG.",
        "测试工程师": "Test ENG.",
        "运维工程师": "Operations ENG.",
        "Java开发工程师": "Java Dev",
        "算法工程师": "Algorithmic ENG.",
        "Web前端开发": "Frontend Dev",
        "软件测试工程师": "Software Test",
        "系统工程师": "Systems ENG.",
        "Android开发工程师": "Android Dev",
        "PHP开发工程师": "PHP Dev",
        "NET开发工程师": ".NET Dev",
        "iOS开发工程师": "IOS Dev",
        "大数据开发工程师": "Big Data Dev",
        "高级软件工程师": "Senior Software ENG.",
        "嵌入式软件开发": "Embedded Software ENG.",
        "C开发工程师": "C Dev",
        "图像算法工程师": "Image Alg. ENG.",
        "Python开发工程师": "Python Dev",
        "测试开发": "Test Dev",
        "系统测试": "System Test",
        "自动化测试": "Auto Test",
        "运维开发": "Operation&Maintenance",
        "Unity3d开发工程师": "Unity3d Dev",
        "ETL开发工程师": "ETL Dev",
        "游戏开发工程师": "Game Dev",
        "游戏测试": "Game Test",
        "机器视觉工程师": "Machine Vision ENG.",
        "C++开发工程师": "C++ Dev",
        "HTML5开发工程师": "HTML5 Dev",
        "性能测试": "Performance Test",
        "小程序开发工程师": "Mini-program Dev",
        "项目经理": "PM",
        "功能测试": "Func Test",
        "移动开发工程师": "Mobile Dev",
        "爬虫开发工程师": "Crawler Dev",
        "图像处理工程师": "Image processing ENG.",
        "区块链开发": "Blockchain Dev",
        "UE4开发工程师": "UE4 Dev",
        "安全测试": "Safety Test",
        "机器学习工程师": "ML ENG.",
        "Cocos2dx开发工程师": "Cocos2dx Dev",
        "游戏客户端开发工程师": "Game client Dev",
        "深度学习工程师": "DL ENG.",
        "系统架构设计师": "System ARCH designer",
        "全栈工程师": "Full Stack ENG.",
        "电子软件开发": "Electronic software Dev",
        "游戏服务端开发工程师": "Game server Dev",
        "系统分析员": "System Analyst",
        "多媒体开发工程师": "Multimedia Dev",
        "数据分析师": "Data Analyst",
        "ERP技术开发": "ERP Dev",
        "脚本开发工程师": "Script Dev",
        "图像识别工程师": "Image Rec.",
        "Go开发工程师": "Go Dev",
        "Ruby开发工程师": "Ruby Dev",
        "测试主管": "Test Supervisor",
        "语音识别工程师": "Speech Rec.",
        "移动端测试": "Mobile terminal Test",
        "项目主管": "Project director",
        "测试经理": "Test Manager",
        "数据库工程师DBA": "DBA",
        "项目助理": "Project Assistant",
        "系统集成工程师": "System Integration ENG.",
        "数据采集工程师": "Data Acquisition ENG.",
        "BI工程师": "BI ENG.",
        "网络安全工程师": "Network Security ENG.",
        "自然语言处理NLP": "NLP",
        "Hadoop工程师": "Hadoop ENG.",
        "项目总监": "Project Director",
        "IT经理": "IT manager",
        "数据标注师": "Data annotator",
        "测试总监": "Test Director",
        "网络工程师IT工程师": "IT ENG.",
        "GIS工程师": "GIS ENG.",
        "ERP实施顾问": "ERP Consultant",
        "电子技术研发工程师": "Electronic Tech. RD",
        "数据建模工程师": "Data Modeling ENG.",
        "游戏运营": "Game Operation",
        "标准化工程师": "Standardization ENG.",
        "网站维护工程师": "Website maintenance",
        "技术总监": "Technical Director"
    }
    let post_list_ch = {}
    let posts_name_ch = []
    for (let ch in post_list_en) {
        post_list_ch[post_list_en[ch]] = ch
    }
    for (let name of posts_name) {
        posts_name_ch.push(post_list_ch[name])
    }
    return posts_name_ch
}

export function getSkillABB(skill_name) {
    let skill_list = {
        "Software Design": "Software Design",
        "Software Sustainment": "Software SUS",
        "Platform-Based Development": "Platform Dev",
        "Social Issues and Professional Practice": "Social Issues&Practice",
        "Data and Information Management": "Data Management",
        "Virtual Systems and Services": "Virtual Sys.",
        "Intelligent Systems": "Intelligent Sys.(AI)",
        "Parallel and Distributed Computing": "Para.&DIS. Computing",
        "Computer Networks": "Computer Net.",
        "Embedded Systems": "Embedded Sys.",
        "Security Technology and Implementation": "Security Tech.",
        "Software Quality verification and validation": "Software Quality Ver.",
        "Graphics and Visualization": "Graphics&Vis",
        "Operating Systems": "OS",
        "Data Structures, Algorithms and Complexity": "Algorithms",
        "Programming Languages": "Programming",
        "Architecture and Organization": "ARCH&Org.",
        "Digital Design": "Digital Design",
        "Circuits and Electronics": "Circuits&Electronics",
        "Signal Processing": "Signal Processing",
        "Mathematics and Statics": "Math&Statistics",
        "Personal Skill": "Personal Skill",
        "Enterprising Skill": "Enterprising Skill",
        "Communication and Presentation": "Comm&Pre",
        "Leadership Skill": "Leadership Skill",
        "Interpersonal Skill": "Interpersonal Skill",
        "Organisational Skill": "Organisational Skill",
        "Team Skill": "Team Skill"
    }
    return skill_list[skill_name]
}

export function abbreviation_skill_name(name) {
    let abbreviation_skill_dict = {
        "Social Issues and Professional Practice": "Social Issues&Practice",
        "Data and Information Management": "Data Management",
        "Virtual Systems and Services": "Virtual Sys.",
        "Intelligent Systems": "Intelligent Sys.",
        "Parallel and Distributed Computing": "Parallel&DIS. Computing",
        "Computer Networks": "Computer Net.",
        "Embedded Systems": "Embedded Sys.",
        "Security Technology and Implementation": "Security Tech.",
        "Software Quality verification and validation": "Software Quality Ver.",
        "Software Design": "Software Design",
        "Software Sustainment": "Software SUS",
        "Platform-Based Development": "Platform Dev",
        "Graphics and Visualization": "Graphics&Vis",
        "Operating Systems": "OS",
        "Data Structures, Algorithms and Complexity": "Algorithms",
        "Programming Languages": "Programming",
        "Architecture and Organization": "ARCH&Org.",
        "Digital Design": "Digital Design",
        "Circuits and Electronics": "Circuits&Electronics",
        "Signal Processing": "Signal Processing",
        "Mathematics and Statics": "Math&Statistics",
        "Personal Skill": "Personal Skill",
        "Enterprising Skill": "Enterprising Skill",
        "Communication and Presentation": "Comm&Pre",
        "Leadership Skill": "Leadership Skill",
        "Interpersonal Skill": "Interpersonal Skill",
        "Organisational Skill": "Organisational Skill",
        "Team Skill": "Team Skill"
    }
    return abbreviation_skill_dict[name]
}

export function translate_company_type(company_type) {
    const companyTypeList = {
        "国企": "SOE",
        "外资（欧美）": "FI",
        "外资（非欧美）": "FI(n)",
        "上市公司": "LC",
        "合资": "JI",
        "民营公司": "PC",
        "外企代表处": "FERO",
        "政府机关": "GO",
        "事业单位": "PI",
        "非营利组织": "NPO",
        "创业公司": "SUC"
    }
    return companyTypeList[company_type]
}


export function get_skill_name(index) {
    let skill_name_chinese_list = [
        "社会问题和实践",
        "数据和信息管理",
        "虚拟系统与服务",
        "AI",
        "并行与分布式",
        "计算机网络",
        "嵌入式系统",
        "安全技术与应用",
        "软件测试与验证",
        "软件设计",
        "软件维护",
        "基于平台的软件开发",
        "图形与可视化",
        "操作系统",
        "数据结构与算法",
        "编程语言",
        "计算机架构与组织",
        "数字逻辑",
        "电子电路",
        "信号处理",
        "数学和统计",
        "项目管理",
        "交流与表达",
        "团队合作",
        "人际交往能力",
        "领导与组织能力",
        "其他个人素质",
        "创新能力"]

    return translate_skill_name(skill_name_chinese_list[index])
}

export function get_vectors_list(vectorsList, numLimit) {
    let resList = []
    for (let i = 0; i < vectorsList.length; i++) {
        resList.push([vectorsList[i], i])
    }
    resList.sort((a, b) => {
        return b[0] - a[0]
    })
    let numList = []
    // let total=0
    // for (const el of resList) {
    //     total+=Math.pow(el[0],4)
    //     console.log('res'+(resList.indexOf(el)+1),[Math.pow(el[0],4).toFixed(4),el[1],total.toFixed(4)])
    // }

    if (numList <= vectorsList.length) {
        for (let i = 0; i < numLimit; i++) {
            numList.push(resList[i][1])
        }
        return numList
    } else {
        return "ERROR"
    }
}

export function get_radar_data(vectorsList, Distribution, numList) {
    let res_data = {
        "vectors": [],
        "distribution": []
    }
    for (let i = 0; i < numList.length; i++) {
        res_data["vectors"].push(vectorsList[numList[i]])
        if (Distribution !== []) {
            res_data["distribution"].push(Distribution[numList[i]])
        }
    }
    return res_data
}

export function get_distribution_data(detail_array) {
    let Distribution = []
    for (let i = 0; i < 28; i++) {
        Distribution.push([])
    }
    for (let i = 0; i < 28; i++) {
        for (let j = 0; j <= 20; j++) {
            Distribution[i].push(0)
        }
    }
    for (const job_vector of detail_array) {
        for (const index in job_vector['skill_vector']) {
            Distribution[index][Math.ceil(job_vector['skill_vector'][index] / radarColor["disLimit"] * 20)] += 1//
        }
    }
    return Distribution
}