import * as d3 from "d3";
import cloud from "d3-cloud"


export function createWordCloud(svg, text, x, y, width, height, fontScale, id) {
    let num = 0;
    let textArr = [];
    for (const textKey in text) {
        num += text[textKey];
    }
    for (const textKey in text) {
        for (let i = 0; i < text[textKey]/num*100; i++) {
            textArr.push(textKey);
        }
    }
    WordCloud(svg, textArr, {x: x,
            y: y,
            width: width,
            height: height,
            fontScale: fontScale},
            id);
}

function WordCloud(svg, text, {
    size = group => group.length, // Given a grouping of words, returns the size factor for that word
    word = d => d, // Given an item of the data array, returns the word
    marginTop = 0, // top margin, in pixels
    marginRight = 0, // right margin, in pixels
    marginBottom = 0, // bottom margin, in pixels
    marginLeft = 0, // left margin, in pixels
    width = 640, // outer width, in pixels
    height = 400, // outer height, in pixels
    maxWords = 250, // maximum number of words to extract from the text
    fontFamily = "sans-serif", // font family
    fontScale = 15, // base font size
    padding = 0, // amount of padding between the words (in pixels)
    rotate = 0, // a constant or function to rotate the words
    x = 100,
    y = 100
} = {}, id) {
    const words = typeof text === "string" ? text.split(/\W+/g) : Array.from(text);

    const data = d3.rollups(words, size, w => w)
        .sort(([, a], [, b]) => d3.descending(a, b))
        .slice(0, maxWords)
        .map(([key, size]) => ({text: word(key), size}));

    const g = svg.append("g")
        .attr("transform", `translate(${x+marginLeft},${y+marginTop})`)
        .attr("width", width)
        .attr("height", height)
        .attr("id", id);

    const cloud1 = cloud()
        .size([width - marginLeft - marginRight, height - marginTop - marginBottom])
        .words(data)
        .padding(padding)
        .rotate(rotate)
        .font(fontFamily)
        .fontSize(d => Math.log10(d.size) * fontScale)
        .on("word", ({size, x, y, rotate, text}) => {
            g.append("text")
                .attr("font-size", size)
                .style("font-weight","500")
                .attr("text-anchor", "middle")
                .attr("x", x)
                .attr("y", y)
                .attr("transform", `rotate(${rotate})`)
                .text(text);
        });

    cloud1.start();
    cloud1.stop();
}