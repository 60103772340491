import * as d3 from "d3";

export async function d3_removeSVG(idx) {
    d3.selectAll(idx).remove();
}

export function d3_creatSVG(idx, width, height, x, y, className, idName) {
    let svg = d3.select(idx).append("svg");
    if (width !== null) {
        svg.attr("width", width);
    }
    if (height !== null) {
        svg.attr("height", height);
    }
    if (x !== null) {
        svg.attr("x", x);
    }
    if (y !== null) {
        svg.attr("y", y);
    }
    if (className !== null) {
        svg.attr("class", className);
    }
    if (idName !== null) {
        svg.attr("id", idName);
    }

    return svg;
}


let BLACK = d3.color("rgb(0, 0, 0)");
let WHITE = d3.color("rgb(255, 255, 255)");
let GRAY = {1: d3.color("rgba(234,234,234,0.5)"),
            2: d3.color("rgb(251,251,251)"),
            3: d3.color("rgb(196,196,196)"),
            };
let YELLOW = {1: d3.color("rgb(255, 218, 123)")};

export {BLACK, WHITE, GRAY, YELLOW}

// 薪资具体值颜色
let salaryDetail = {
    1: d3.color("rgb(251,251,251)"),
    2: d3.color("rgb(251,251,251)"),
    3: d3.color("rgb(251,251,251)")
};

export {salaryDetail}

// 技能熟练度颜色
let skillColor = {1: d3.color("#f2dbd9"),
    2: d3.color("#bea59d"),
    3: d3.color("#7c6166")};

// 软硬技能颜色
let postColor = {"hard": d3.color("#792e41"),
    "soft": d3.color("#c35f78")};

// 连线颜色
let lineColor = {"gray": d3.color("rgba(100,100,100,0.3)"),
    "purple": d3.color("rgb(160,175,255)"),
    "blue": d3.color("rgb(50, 120, 255)"),
    "yellow": d3.color("rgba(255,159,77,0.4)")};

// 薪资分类颜色
let salaryColor = {6: d3.color("#2e3142"),
    5: d3.color("#4e5768"),
    4: d3.color("#587a91"),
    3: d3.color("#89a5b7"),
    2: d3.color("#9dbfd7"),
    1: d3.color("#d1e6f4"),
};

// 平均技能原颜色
let avgSkillRAWColor = skillColor[3];

export {skillColor, postColor, lineColor, salaryColor, avgSkillRAWColor}

let Linemask = d3.color("rgba(0, 70, 255, 0.1)");
export {Linemask}

// 放缩工具
let scaleQuantize_min = d3.scaleQuantize()
    .domain([0, 0.1])
    .range([0.3, 0.45, 0.6, 0.75]);
let scaleQuantize_max = d3.scaleQuantize()
    .domain([0.1, 1])
    .range([0.95, 0.98, 1]);

let scaleLinear = d3.scaleQuantize()
    .domain([0, 0.5])
    .range([0.1, 0.35, 0.4, 0.5, 0.8, 0.85, 0.88, 0.9, 0.95, 0.98, 1]);

let salaryColorGrad = {1: d3.interpolate(d3.rgb(255, 255, 228), d3.rgb(255, 230, 0)),
                        2: d3.interpolate(d3.rgb(255, 246, 225), d3.rgb(255, 176, 0)),
                        3: d3.interpolate(d3.rgb(255, 239, 227), d3.rgb(255, 109, 0)),
                        4: d3.interpolate(d3.rgb(255, 206, 199), d3.rgb(255, 33, 0)),
                        5: d3.interpolate(d3.rgb(175, 188, 255), d3.rgb(0, 70, 255)),
                        0: d3.interpolate(d3.rgb(190, 200, 255), d3.rgb(0, 70, 255))};

export {scaleLinear, salaryColorGrad}

export function color_transparencyGradient(rawColor, aControlValue, mode) {
    let color = rawColor;
    if (mode === 0) {
        if (aControlValue >= 0.1) {
            color.opacity = scaleQuantize_max(aControlValue);
        } else {
            color.opacity = scaleQuantize_min(aControlValue);
        }
    } else {
        color.opacity = scaleLinear(aControlValue);
    }
    return color
}

// 公司类型
let companyCategoryColor = {
    "国企": d3.color("#FF9999"),
    "外资（欧美）": d3.color("#CCFFCC"),
    "外资（非欧美）": d3.color("#CCFF99"),
    "上市公司": d3.color("#CCCCFF"),
    "合资": d3.color("#FFFFCC"),
    "民营公司": d3.color("#99CCFF"),
    "外企代表处": d3.color("#65CC99"),
    "政府机关": d3.color("#CCFFFF"),
    "事业机关": d3.color("#6699CC"),
    "非营利组织": d3.color("#99CCCC"),
    "创业公司": d3.color("#66CCCC")
}

export {companyCategoryColor}

// 具体薪资情况
let detailSalary = {
    "maxSalary": d3.color("#CC3399"),
    "minSalary": d3.color("#CC99CC"),
    "averageSalary": d3.color("#9933CC"),
}

export {detailSalary}

//overview color

export {skill_bar_color,post_bar_color,salary_bar_color,skill_link_color,skill_collinear_color,skill_post_link_color,post_salary_link_color}
export {skill_text_fontSize,bar_state,salary_limit}

//skill bar
let skill_bar_color={
    "1":skillColor[3],//
    "2":skillColor[2],//
    "3":skillColor[1]//
}
//post bar
let post_bar_color={
    "technical":postColor["hard"],//
    "foundational":postColor["soft"]//
}
//salary bar
let salary_bar_color={
    "1": salaryColor[1],
    "2": salaryColor[2],
    "3": salaryColor[3],
    "4": salaryColor[4],
    "5": salaryColor[5],
    "6": salaryColor[6]
}

//skill link
let skill_link_color={
    "on":lineColor["purple"],//
    "off":lineColor["gray"]
}
//skill collinear
let skill_collinear_color=lineColor["blue"]//
//skill post link
let skill_post_link_color=lineColor["gray"]//lineColor["blue"]
//post salary link
let post_salary_link_color=lineColor["gray"]//lineColor["yellow"]
//skill text font-size
let skill_text_fontSize="12px"
//class on
let bar_state={
    "on":{
        "stroke":"gray",
        "stroke-width":"3px"
    },
    "off":{
        "stroke":"#fbfbfb",
        "stroke-width":"1px"
    }
}
//salary max num
let salary_limit=23


const Efont = "sans-serif";
export {Efont}


//MiddleviewCluster
const ClusterSvg={
    "width":780,
    "height":650,
    "rect1":65*3,
    "rect2":65*9
}
export {ClusterSvg}

//radar color
const radarColor={
    "border":"rgba(33,37,41,0.5)",
    "link":"rgba(92,92,92,0.8)",
    "point":"rgba(92,92,92,0.8)",
    "backOff":"#fbfbfb",
    "backOn":"#eeeeee",
    "line":"rgba(10,190,200,0.8)",
    "buttonOut":"rgba(10,190,200,0.8)",
    "buttonOver":"rgba(192,75,85,0.8)",
    "limit":1,
    "disLimit":0,
    "level1":"rgba(227,216,255,1.0)",
    "level2":"rgba(199,190,255,1.0)",
    "level3":"rgba(171,163,255,1.0)",
    "level4":"rgba(142,137,253,1.0)",
    "level5":"rgba(116,115,230,1.0)",
    "level6":"rgba(91,94,206,1.0)",
    "level7":"rgba(64,73,181,1.0)",
    "level8":"rgba(31,54,158,1.0)",
    "level9":"rgba(5,33,132,1.0)",
    "level10":"rgba(0,11,102,1.0)"
}
export {radarColor}

//post color
const postRadarColor={
    "1":"rgba(1,193,245,0.9)",
    "2":"rgba(215,0,9,0.9)",
    "3":"rgba(236,229,0,0.9)",
    "4":"rgba(92,151,101,0.9)",
    "5":"rgba(255,190,86,0.9)",
    "6":"rgba(205,96,0,0.9)",
    "7":"rgba(1,182,117,0.9)",
    "8":"rgba(255,59,161,0.9)",
    "9":"rgba(122,70,8,0.9)",
    "10":"rgba(170,118,161,0.9)"
}
export {postRadarColor}
