<template>
  <div id="Middleview">
    <h3>Post Exploration View</h3>
    <b-button @click="fetchData" size="sm">Search Posts</b-button>
    <div id="ClustersView" @contextmenu.prevent></div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import {cluster_radar, vector_text} from "@/common/tools_for_d3";
import pipeService from "@/common/pipe-service";
import {getClusterInfo} from "@/common/data-service";
import {radarColor, ClusterSvg, postRadarColor} from "@/common/svg-help";
import {
  get_radar_r,
  get_radar_axis,
  get_vectors_list,
  get_radar_data,
  get_distribution_data,
  translate_post_name, get_skill_name, translate_company_type, collision_detection
} from "@/common/tools_for_data";

export default {
  name: "MiddleviewCluster",
  data() {
    return {
      postID: ['Android开发工程师'],
      locations: ['北京'],
      qualifications: ["本科"],
      industries: [],
      experiences: [],
      clusters: [],
      clusterColor: [],
      averageVector: [],
      averageDistribution: [],
      jobs: [],
      focusClusterID: "",
      focusNumList: [],
      focusCluster: [],
      focusDistribution: [],
      jobColor: "",
      jobPost: "",
      jobAxisX: {},
      jobAxisY: {},
      jobRect: {},
      jobRectRadarRealNum: {},
      jobRectRadarAxisBox: {},
      jobRectStartX: {},
      jobRectStartY: {},
      randomAxisBox: [],
      leftID: "",
      rightID: "",
    }
  },
  watch: {},
  methods: {
    fetchData() {
      if (this.postID.length > 10) {
        alert("The number of posts is limited to 10")
      } else {
        getClusterInfo(this.postID, this.locations, this.qualifications, this.industries, this.experiences, (res) => {
          this.clusters = res.data
          let disMax = 0
          // console.log(res.data)
          for (const index in this.clusters) {
            this.clusters[index]['cluster_id'] = parseInt(index)
            // console.log(this.clusters[index]['detail_array'])
            for (const skill_array of this.clusters[index]['detail_array']) {
              for (const num of skill_array['skill_vector']) {
                if (num > disMax) disMax = num
              }
            }
          }
          disMax = 1
          for (const index in this.clusters) {
            for (const skill in this.clusters[index]['ave_vector']) {
              this.clusters[index]['ave_vector'][skill] /= disMax
            }
            for (const skill_array of this.clusters[index]['detail_array']) {
              for (const skill in skill_array['skill_vector']) {
                skill_array['skill_vector'][skill] /= disMax
              }
            }
          }
          radarColor['disLimit'] = disMax
          this.averageVector = []
          this.averageDistribution = []
          for (let i = 0; i < 28; i++) {
            this.averageVector.push(0)
            this.averageDistribution.push([])
          }

          //distribution指0-1的分布
          for (let i = 0; i < 28; i++) {
            for (let j = 0; j <= 20; j++) {
              this.averageDistribution[i].push(0)
            }
          }
          for (const cluster of this.clusters) {
            let distribution = get_distribution_data(cluster['detail_array'])
            for (let i = 0; i < 28; i++) {
              this.averageVector[i] += Math.pow(cluster["ave_vector"][i], 4)
              for (let j = 0; j <= 20; j++) {
                this.averageDistribution[i][j] += distribution[i][j]
              }
            }
          }
          for (let i = 0; i < 28; i++) {
            this.averageVector[i] /= this.clusters.length
            this.averageVector[i] = Math.sqrt(Math.sqrt(this.averageVector[i]))
          }
          d3.selectAll(".radar").remove()
          d3.selectAll(".rect2").remove()
          d3.selectAll(".rect3").remove()
          this.create_clusters()

          // console.log('post',this.postID)
          // console.log('locations',this.locations)
          // let limit=0
          // let aveVector=[]
          // for (let i = 0; i < 28; i++) {
          //   aveVector.push([0,0])
          // }
          // for (const cluster of this.clusters) {
          //   console.log('id',cluster['cluster_id'])
          //   let numList=get_vectors_list(cluster['ave_vector'],28)
          //   let newVector=[]
          //   let total=0
          //   limit+=1
          //   for (let i = 0; i < 28; i++) {
          //     let n=Math.pow(cluster['ave_vector'][numList[i]],4)
          //     total+=n
          //     newVector.push([i,parseFloat(n.toFixed(4)),parseFloat(total.toFixed(4))])
          //     aveVector[i][0]+=n
          //     aveVector[i][1]+=total
          //     console.log(newVector[i])
          //   }
          //   if (limit===6) {
          //     break
          //   }
          // }
          // console.log('ave')
          // let base
          // if (this.clusters.length<6) base=this.clusters.length
          // else base=6
          // console.log(base)
          // for (let i = 0; i < 28; i++) {
          //   aveVector[i][0]/=base
          //   aveVector[i][1]/=base
          //   console.log([i,parseFloat(aveVector[i][0].toFixed(4)),parseFloat(aveVector[i][1].toFixed(4))])
          // }
        })
      }
    },
    create_svg() {
      const width = ClusterSvg["width"], height = ClusterSvg["height"]

      d3.select("#ClustersView").append("svg")
          .attr("width", width).attr("height", height)
          .attr("id", "ClustersSvg")

      d3.select("#ClustersSvg").append("rect")
          .attr("x", 0).attr("y", 0).attr("width", width).attr("height", height)
          .attr("rx", "0px").attr("ry", "0px").attr("fill", "#fbfbfb").attr("stroke", "rgba(33, 37, 41, 0.5)")
          .attr("class", "viewRect")

      d3.select("#ClustersSvg").append("rect")
          .attr("x", 0).attr("y", (height - ClusterSvg["rect1"]) + "px").attr("width", (ClusterSvg["rect1"]) + "px")
          .attr("height", (ClusterSvg["rect1"]) + "px").attr("rx", "0px").attr("ry", "0px").attr("fill", "#fbfbfb")
          .attr("stroke", "rgba(33, 37, 41, 0.5)")

      d3.select("#ClustersSvg").append("rect")
          .attr("x", (ClusterSvg["rect1"]) + "px").attr("y", (ClusterSvg["height"] - ClusterSvg["rect1"]) + "px")
          .attr("width", (ClusterSvg["rect2"]) + "px").attr("height", (ClusterSvg["rect1"]) + "px")
          .attr("rx", "0px").attr("ry", "0px").attr("fill", "#fbfbfb").attr("stroke", "rgba(33, 37, 41, 0.5)")
    },
    create_clusters() {
      d3.selectAll(".radar").remove()
      d3.selectAll(".rect2").remove()
      let clusters = this.clusters
      this.randomAxisBox = []
      let vectorLength = clusters[0]['radius']//this.get_cluster_radius()
      let axisBox = []
      for (const cluster of clusters) {
        // axisBox.push({'x':cluster['axis_X'],'y':cluster['axis_Y'],'num':cluster['detail_array'].length})
        let x = parseInt(cluster['axis_X'].toFixed(4)) + ClusterSvg["width"] / 2,
            y = parseInt(cluster['axis_Y'].toFixed(4)) + (ClusterSvg["height"] - ClusterSvg["rect1"]) / 2
        let axis = {'x': x, 'y': y, 'num': cluster['detail_array'].length}
        axisBox.push(axis)//
      }
      // console.log(axisBox)
      this.randomAxisBox = collision_detection(axisBox, vectorLength)
      let svg = d3.select("#ClustersSvg")
      let numList = get_vectors_list(this.averageVector, 8)
      let modelList = []
      let that = this
      for (let i = 0; i < 28; i++) {
        modelList.push(i)
      }

      for (const cluster of clusters) {
        let distribution = cluster['distribution']//get_distribution_data(cluster['detail_array'])
        let radarData = get_radar_data(cluster["ave_vector"], distribution, numList)
        let backColor
        if (this.clusterColor.find(x => x["ID"] === cluster["post_id"])) {
          backColor = this.clusterColor.find(x => x["ID"] === cluster["post_id"])["color"]
        } else {
          backColor = this.random_color(cluster["post_id"])
        }
        let axisData = this.randomAxisBox[cluster['cluster_id']]
        // console.log(this.clusterColor,cluster["post_id"],backColor,clusters.indexOf(cluster))
        cluster_radar(svg, axisData[0], axisData[1], vectorLength,
            radarData["vectors"], radarData['distribution'], numList, backColor, cluster["cluster_id"], "cluster")//
      }
      cluster_radar(svg, ClusterSvg["rect1"] / 2, ClusterSvg["height"] - ClusterSvg["rect1"] / 2, 80,
          this.averageVector, this.averageDistribution, modelList, radarColor["border"], "averageCluster", "averageCluster")
      svg.append("text").attr("font-size", 12).attr("text-anchor", "left")
          .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(33, 37, 41, 0.5)")
          .attr("x", ((0) + 6) + "px")
          .attr("y", (ClusterSvg['height'] - 6) + "px")
          .attr("class", "rect1 averageCluster radar")
          .text("AverageCluster")

      //画样例图
      let sampleSpace = 25,
          sampleR = ClusterSvg["rect1"] - 2 * sampleSpace,
          sampleLength = sampleR * Math.sin(Math.PI / 12) * 2
      let sampleTransX = ClusterSvg['rect1']
      cluster_radar(svg, ClusterSvg['rect1'] * 3 / 2, ClusterSvg["height"] - ClusterSvg["rect1"] / 2, 35,
          [0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5], [], numList, radarColor["border"], "sampleCluster", "sampleCluster")
      for (const index in numList) {
        let vectorTextX, vectorTextY
        vectorTextX = ClusterSvg['rect1'] * 3 / 2 - 70 * Math.sin(Math.PI * 2 / numList.length * (index))
        vectorTextY = ClusterSvg["height"] - ClusterSvg["rect1"] / 2 - 70 * Math.cos(Math.PI * 2 / numList.length * (index)) - 5
        let textArray = get_skill_name(numList[index]).replace(/,/g, '').split(' ')
        vector_text(svg, vectorTextX, vectorTextY, textArray)
      }
      let path1 = "M " + (ClusterSvg['rect1'] + sampleSpace + sampleTransX) + " " + (ClusterSvg['height'] - ClusterSvg['rect1'] / 2 - sampleSpace / 2) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR + sampleTransX) + " " + (ClusterSvg['height'] - ClusterSvg['rect1'] / 2 - sampleSpace / 2) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR - sampleLength * Math.cos(Math.PI * 5 / 12) + sampleTransX) + " " +
              (ClusterSvg['height'] - ClusterSvg['rect1'] / 2 - sampleSpace / 2 - sampleR / 2) + " z",
          path2 = "M " + (ClusterSvg['rect1'] + sampleSpace + sampleTransX) + " " + (ClusterSvg['height'] - sampleSpace / 2) +
              " L " + (ClusterSvg['rect1'] * 2 - sampleSpace + sampleTransX) + " " + (ClusterSvg['height'] - sampleSpace / 2) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR * Math.cos(Math.PI / 6) + sampleTransX) + " " +//-
              (ClusterSvg['height'] - ClusterSvg['rect1'] / 2 + sampleSpace / 2) + " z",
          path2Inner1 = "M " + (ClusterSvg['rect1'] + sampleSpace + sampleTransX) + " " + (ClusterSvg['height'] - sampleSpace / 2) +
              " L " + (ClusterSvg["rect1"] + sampleSpace + sampleR * 8 / 30 - sampleLength * 8 / 30 * 0.3 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 8 / 30 * 0.3 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg["rect1"] + sampleSpace + sampleR * 11 / 30 - sampleLength * 11 / 30 * 0.4 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 11 / 30 * 0.4 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg["rect1"] + sampleSpace + sampleR * 14 / 30 - sampleLength * 14 / 30 * 0.55 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 14 / 30 * 0.55 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg["rect1"] + sampleSpace + sampleR * 17 / 30 - sampleLength * 17 / 30 * 0.65 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 17 / 30 * 0.65 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg["rect1"] + sampleSpace + sampleR * 20 / 30 - sampleLength * 20 / 30 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 20 / 30 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg["rect1"] + sampleSpace + sampleR * 26 / 30 - sampleLength * 26 / 30 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 26 / 30 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg["rect1"] + sampleSpace + sampleR * 28 / 30 - sampleLength * 28 / 30 * 0.2 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 28 / 30 * 0.2 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg["rect1"] + sampleSpace + sampleR * 30 / 30 + sampleTransX) + " " + (ClusterSvg['height'] - sampleSpace / 2) + " z",
          path2Inner2 = "M " + (ClusterSvg['rect1'] + sampleSpace + sampleR * 20 / 30 + sampleTransX) + " " + (ClusterSvg['height'] - sampleSpace / 2) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR * 21 / 30 - sampleLength * 21 / 30 * 0.3 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 21 / 30 * 0.3 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR * 22 / 30 - sampleLength * 22 / 30 * 0.15 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 22 / 30 * 0.15 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR * 25 / 30 - sampleLength * 25 / 30 * 0.55 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 25 / 30 * 0.55 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR * 26 / 30 + sampleTransX) + " " + (ClusterSvg['height'] - sampleSpace / 2) + " z",
          path2Inner3 = "M " + (ClusterSvg['rect1'] + sampleSpace + sampleR * 20 / 30 + sampleTransX) + " " + (ClusterSvg['height'] - sampleSpace / 2) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR * 20 / 30 - sampleLength * 20 / 30 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 20 / 30 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR * 26 / 30 - sampleLength * 26 / 30 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 26 / 30 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR * 26 / 30 + sampleTransX) + " " + (ClusterSvg['height'] - sampleSpace / 2) + " z"

      svg.append("path").attr("d", path1).attr("stroke", radarColor["border"]).attr("stroke-width", 1 + 'px')
          .attr("fill", "#fbfbfb").attr("class", "rect2 Sample radar")
      svg.append("path").attr("d", path2).attr("stroke", radarColor["border"]).attr("stroke-width", 1 + 'px')
          .attr("fill", "#fbfbfb").attr("class", "rect2 Sample radar")
      svg.append("path").attr("d", path2Inner1).attr("stroke", radarColor["border"]).attr("stroke-width", 0.01 + 'px')
          .attr("fill", radarColor["level1"]).attr("class", "rect2 Sample radar")
      svg.append("path").attr("d", path2Inner3).attr("stroke", radarColor["border"]).attr("stroke-width", 0.01 + 'px')
          .attr("fill", radarColor["level2"]).attr("class", "rect2 Sample radar")
      svg.append("path").attr("d", path2Inner2).attr("stroke", radarColor["border"]).attr("stroke-width", 0.01 + 'px')
          .attr("fill", radarColor["level3"]).attr("class", "rect2 Sample radar")

      let path1X1 = ClusterSvg['rect1'] + sampleSpace + sampleTransX,
          path1Y1 = ClusterSvg['height'] - ClusterSvg['rect1'] / 2 - sampleSpace / 2//
      for (let i = 1; i < 5; i++) {
        svg.append("line").attr("x1", path1X1).attr("y1", path1Y1)
            .attr("x2", (ClusterSvg['rect1'] + sampleSpace + sampleR - sampleLength * 0.2 * i * Math.sin(Math.PI / 12) + sampleTransX))
            .attr("y2", (ClusterSvg['height'] - ClusterSvg['rect1'] / 2 - sampleSpace / 2 - sampleLength * 0.2 * i * Math.cos(Math.PI / 12)))//
            .attr("stroke", radarColor["border"]).attr("stroke-width", 0.2 + 'px')
            .attr("class", "rect2 Sample radar")
        svg.append("text").attr("font-size", 7).attr("text-anchor", "left")
            .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,0.4)")
            .attr("x", (ClusterSvg['rect1'] + sampleSpace + sampleR - sampleLength * 0.2 * i * Math.sin(Math.PI / 12) - 8 + sampleTransX))
            .attr("y", (ClusterSvg['height'] - ClusterSvg['rect1'] / 2 - sampleSpace / 2 - sampleLength * 0.2 * i * Math.cos(Math.PI / 12) + 8))
            .attr("class", "rect2 Sample radar")
            .text(i * 4)
      }
      svg.append("text").attr("font-size", 7).attr("text-anchor", "left")
          .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,0.4)")
          .attr("x", (ClusterSvg['rect1'] + sampleSpace + sampleR - sampleLength * Math.sin(Math.PI / 12) - 7.5 + sampleTransX))
          .attr("y", (ClusterSvg['height'] - ClusterSvg['rect1'] / 2 - sampleSpace / 2 - sampleLength * Math.cos(Math.PI / 12) + 11))
          .attr("class", "rect2 Sample radar")
          .text("20")
      svg.append("text").attr("font-size", 8).attr("text-anchor", "left")
          .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,0.6)")
          .attr("x", (path1X1 - 3.5))
          .attr("y", (path1Y1 + 8))
          .attr("class", "rect2 Sample radar")
          .text("0")
      // svg.append("rect").attr("x",(path1X1)).attr("y",(path1Y1-8)).attr("width","1px").attr("height","8px")
      //     .attr("fill","rgba(0,0,0,0.6)").attr("class","rect2 Sample radar")
      svg.append("rect").attr("x", (path1X1 + sampleR / 20)).attr("y", (path1Y1 - sampleR / 20 * Math.tan(Math.PI / 6))).attr("width", "1px").attr("height", (sampleR / 20 * Math.tan(Math.PI / 6)) + "px")
          .attr("fill", "rgba(0,0,0,0.6)").attr("class", "rect2 Sample radar")
      svg.append("text").attr("font-size", 8).attr("text-anchor", "left")
          .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,0.6)")
          .attr("x", (path1X1 + sampleR / 20 - 3.5))
          .attr("y", (path1Y1 + 8))
          .attr("class", "rect2 Sample radar")
          .text("0.05")
      svg.append("text").attr("font-size", 8).attr("text-anchor", "left")
          .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,0.6)")
          .attr("x", (path1X1 + sampleR - 3.5))
          .attr("y", (path1Y1 + 8))
          .attr("class", "rect2 Sample radar")
          .text(radarColor["disLimit"])
      svg.append("text").attr("font-size", 8).attr("text-anchor", "middle")
          .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,0.6)")
          .attr("transform", "translate(" +
              (path1X1 + sampleR - sampleLength / 2 * Math.sin(Math.PI / 12) - 7.5 + 10) + "," +
              (path1Y1 - sampleLength / 2 * Math.cos(Math.PI / 12)) + ") rotate(75)")
          .attr("x", 0)
          .attr("y", 0)
          .attr("class", "rect2 Sample radar")
          .text("Posts num")
      svg.append("text").attr("font-size", 8).attr("text-anchor", "middle")
          .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,0.6)")
          .attr("x", (path1X1 + sampleR / 2))
          .attr("y", (path1Y1 + 8))
          .attr("class", "rect2 Sample radar")
          .text("Skill vector value")


      //         let sampleVector=[],sampleDistribution=[]
      // for (let i = 0; i < 28; i++) {
      //   sampleVector.push(radarColor['disLimit']*2/3)
      //   sampleDistribution.push([])
      //   for (let j = 0; j <= radarColor["disLimit"]; j++) {
      //     let sampleY
      //     if (j<=i&&j<=(radarColor["disLimit"]*2/3-2)) {
      //       sampleY=(i+1)*5
      //     } else {
      //       sampleY=0
      //     }
      //     sampleDistribution[i].push(sampleY)
      //   }
      // }
      // svg.append("text").attr("font-size",12).attr("text-anchor","left")
      //     .style("font-family","sans-serif").style("font-weight","350").style("fill","rgba(33, 37, 41, 0.5)")
      //     .attr("x",((ClusterSvg['rect1'])+6)+"px")
      //     .attr("y",(ClusterSvg['height']-6)+"px")
      //     .attr("class","rect2 Sample radar")
      //     .text("Sample")
      // cluster_radar(svg,ClusterSvg['rect1']+ClusterSvg["rect1"]/2,
      //     ClusterSvg["height"]-ClusterSvg["rect1"]/2,80,
      //     sampleVector,sampleDistribution,radarColor["border"],"sampleCluster","sampleCluster")
      let level = ['1-20', '21-40', '41-60', '61-80', '81-100', '101-120', '121-140', '141-160', '161-180', '181-200']
      let sampleHeight = (ClusterSvg["rect1"] - sampleSpace) / 10
      for (let i = 1; i <= 10; i++) {
        svg.append('rect').attr("x", (ClusterSvg['rect1'] * 2 - 10 + sampleTransX) + "px")
            .attr("y", (ClusterSvg["height"] - ClusterSvg["rect1"] + sampleSpace / 2 + sampleHeight * (i - 1)) + "px")
            .attr("width", 10 + "px").attr("height", sampleHeight + "px")
            .attr("rx", (0) + "px").attr("ry", (0) + "px").attr("fill", radarColor["level" + (i)])
            .attr("stroke", "rgba(33, 37, 41, 0.1)").attr("class", "rect2").attr("id", "sampleLevel_" + (i))
        // .on("mouseover",function(){
        //   let id=this.id
        //   svg.append("text").attr("font-size",8).attr("text-anchor","left")
        //       .style("font-family","sans-serif").style("font-weight","350").style("fill","rgba(33, 37, 41, 0.4)")
        //       .attr("x",(parseInt(d3.select("#"+id).attr("x"))+15)+"px")
        //       .attr("y",(parseInt(d3.select("#"+id).attr("y"))+8)+"px")
        //       .attr("class","rect2 sampleLevelText")
        //       .text(level[(i)])
        // })
        // .on("mouseout",function(){
        //   d3.selectAll(".sampleLevelText").remove()
        // })
        svg.append("text").attr("font-size", 7).attr("text-anchor", "left")
            .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(33, 37, 41, 0.4)")
            .attr("x", (ClusterSvg['rect1'] * 2 - 10 + 13 + sampleTransX) + "px")
            .attr("y", (ClusterSvg["height"] - ClusterSvg["rect1"] + sampleSpace / 2 + sampleHeight * (i - 0.5) + 3) + "px")
            .attr("class", "rect2 sampleLevelText")
            .text(level[(i - 1)])
      }
      let postIndex = this.clusterColor.length
      for (let i = 0; i < postIndex; i++) {
        let post = this.clusterColor[i]
        let fillColor = post['color'].substring(0, post['color'].length - 2) + "1)"
        svg.append("circle").attr("cx", (ClusterSvg['rect1'] * 2 + 45 + sampleTransX) + "px")
            .attr("cy", (ClusterSvg['height'] - ClusterSvg['rect1'] / 2 - 80 + 17.8 * i) + "px")
            .attr("r", (5) + "px").attr("fill", fillColor)
            .attr("stroke", post['color']).attr("stroke-width", '1px')
            .attr("class", "rect2").attr("id", "samplePost_" + (i))
        svg.append("text").attr("font-size", 10).attr("text-anchor", "left")
            .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(33, 37, 41, 0.5)")
            .attr("x", (ClusterSvg['rect1'] * 2 + 45 + 10 + sampleTransX) + "px")
            .attr("y", (ClusterSvg['height'] - ClusterSvg['rect1'] / 2 - 80 + 17.8 * i + 4) + "px")
            .attr("class", "rect2 samplePost")
            .text(translate_post_name(post["ID"]))
      }


      //为cluster绑定点击事件：生成jobs‘雷达图
      document.querySelectorAll(".radar").forEach(circle => {
        if (circle.id && circle.id.split("_")[1] !== "averageCluster" && circle.id.split("_")[1] !== "sampleCluster") {
          let that = this
          let ID = circle.id.split("_")[1]
          circle.onclick = function () {
            // console.log('click do',ID)
            for (const cluster of clusters) {
              // console.log('cluster_id',cluster['cluster_id'])
              if (cluster["cluster_id"] === parseInt(ID)) {
                that.focusClusterID = cluster['cluster_id']
                that.focusCluster = cluster["ave_vector"]
                that.focusNumList = numList
                that.focusDistribution = cluster['distribution']//get_distribution_data(cluster['detail_array'])
                that.jobColor = that.clusterColor.find(x => x["ID"] === cluster["post_id"])["color"]
                that.jobPost = cluster["post_id"]

                that.jobs = cluster["detail_array"]
                that.jobAxisX = {}
                that.jobAxisY = {}
                that.jobRect = {}
                that.jobRectRadarRealNum = {}
                that.jobRectRadarAxisBox = {}
                that.jobRectStartX = {}
                that.jobRectStartY = {}
                for (const job of that.jobs) {
                  that.jobAxisX[job["enttype"]] = 0
                  that.jobAxisY[job["salary"]] = 0
                  that.jobRect[job["enttype"]] = {}
                  that.jobRectRadarRealNum[job["enttype"]] = {}
                  that.jobRectRadarAxisBox[job["enttype"]] = {}
                  that.jobRectStartX[job["enttype"]] = 0
                  that.jobRectStartY[job["salary"]] = 0
                }
                for (const job of that.jobs) {
                  that.jobRect[job["enttype"]][job["salary"]] = 0
                  that.jobRectRadarRealNum[job["enttype"]][job["salary"]] = 0
                  that.jobRectRadarAxisBox[job["enttype"]][job["salary"]] = []
                }
                for (const job of that.jobs) {
                  that.jobAxisX[job["enttype"]] += 1
                  that.jobAxisY[job["salary"]] += 1
                  that.jobRect[job["enttype"]][job["salary"]] += 1
                }
                d3.selectAll(".radar").remove()
                d3.selectAll(".rect2").remove()
                d3.selectAll(".rect3").remove()
                that.create_jobs()
                // console.log('click done')

                break
              }
            }
          }
          circle.onmouseover = function () {
            for (const cluster of clusters) {
              if (cluster['cluster_id'] === parseInt(ID)) {
                d3.selectAll(".averageCluster").remove()
                cluster_radar(svg, ClusterSvg["rect1"] / 2,
                    ClusterSvg["height"] - ClusterSvg["rect1"] / 2, 80,
                    cluster["ave_vector"], cluster['distribution'], modelList,//get_distribution_data(cluster['detail_array'])
                    that.clusterColor.find(x => x["ID"] === cluster["post_id"])["color"]
                    , "focusClusterTep", "focusClusterTep")
                svg.append("text").attr("font-size", 12).attr("text-anchor", "left")
                    .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(33, 37, 41, 0.5)")
                    .attr("x", (6) + "px")
                    .attr("y", (ClusterSvg['height'] - 6) + "px")
                    .attr("class", "rect3 focusClusterTep radar")
                    .text("ClusterID:" + cluster['cluster_id'])

              }
            }
            let id = circle.id
            if (id.split('_').length === 3 && id.split('_')[2] === 'back') {
              // console.log(id.split('_'))
              let d, legendX, legendY, R = 50
              d = circle.getAttribute('d').split(' ')
              // console.log(d)
              legendX = d[1]
              legendY = parseInt(d[2]) + vectorLength
              legendX = legendX - (legendX - ClusterSvg['width'] / 2) / Math.abs(legendX - ClusterSvg["width"] / 2) *
                  (R + vectorLength) / Math.sqrt(2)
              legendY = legendY - (legendY - (ClusterSvg["height"] - ClusterSvg["rect1"]) / 2) /
                  Math.abs(legendY - (ClusterSvg["height"] - ClusterSvg["rect1"]) / 2) * (R + vectorLength) / Math.sqrt(2)
              let cluster = clusters[id.split('_')[1]]
              // console.log(cluster)
              let distribution = cluster['distribution']//get_distribution_data(cluster['detail_array'])
              let radarData = get_radar_data(cluster["ave_vector"], distribution, numList)
              let backColor = that.clusterColor.find(x => x["ID"] === cluster["post_id"])["color"]
              cluster_radar(svg, legendX, legendY, R, radarData["vectors"], radarData['distribution'],
                  numList, backColor, 'Legend', "Legend")
            }
          }
          circle.onmouseout = function () {
            d3.selectAll(".focusClusterTep").remove()
            d3.selectAll(".Legend").remove()
            cluster_radar(svg, ClusterSvg["rect1"] / 2, ClusterSvg["height"] - ClusterSvg["rect1"] / 2, 80,
                that.averageVector, that.averageDistribution, modelList, radarColor["border"], "averageCluster", "averageCluster")
            svg.append("text").attr("font-size", 12).attr("text-anchor", "left")
                .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(33, 37, 41, 0.5)")
                .attr("x", ((0) + 6) + "px")
                .attr("y", (ClusterSvg['height'] - 6) + "px")
                .attr("class", "rect1 averageCluster radar")
                .text("AverageCluster")
          }
        }
      })
      // document.querySelectorAll(".radar").forEach(radar=>{
      //   let id=radar.id
      //   if (id.split('_').length===3||id.split('_')[2]==='back') {
      //     radar.onmouseover=function(){
      //       let d,legendX,legendY,R=80
      //       d=radar.getAttribute('d').split(' ')
      //       legendX=d[1]
      //       legendY=d[2]+vectorLength
      //       legendX=legendX-(legendX-ClusterSvg['width']/2)/Math.abs(legendX-ClusterSvg["width"]/2)*(R+vectorLength)
      //       legendY=legendY-(legendY-ClusterSvg["height"]/2)/Math.abs(legendY-ClusterSvg["height"]/2)*(R+vectorLength)
      //       let cluster=clusters[id.split('_')[1]]
      //       let distribution=get_distribution_data(cluster['detail_array'])
      //       let radarData=get_radar_data(cluster["ave_vector"],distribution,numList)
      //       let backColor=that.clusterColor.find(x=>x["ID"]===cluster["post_id"])["color"]
      //       cluster_radar(svg,legendX,legendY,R,radarData["vectors"],radarData['distribution'],
      //           numList,backColor,'Legend',"legend")
      //     }
      //     radar.onmouseout=function(){
      //       d3.selectAll(".Legend").remove()
      //     }
      //   }
      // })
    },
    create_jobs() {
      // console.log('job do')
      let svg = d3.select("#ClustersSvg")
      let startX = 20
      let startY = 0
      let that = this
      let focusNumList = this.focusNumList
      //画样例
      let sampleSpace = 25,
          sampleR = ClusterSvg["rect1"] - 2 * sampleSpace,
          sampleLength = sampleR * Math.sin(Math.PI / 12) * 2
      let sampleTransX = ClusterSvg['rect1']
      cluster_radar(svg, ClusterSvg['rect1'] * 3 / 2, ClusterSvg["height"] - ClusterSvg["rect1"] / 2, 35,
          [0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5], [], focusNumList, radarColor["border"], "sampleCluster", "sampleCluster")
      for (const index in focusNumList) {
        let vectorTextX, vectorTextY
        vectorTextX = ClusterSvg['rect1'] * 3 / 2 - 70 * Math.sin(Math.PI * 2 / focusNumList.length * (index))
        vectorTextY = ClusterSvg["height"] - ClusterSvg["rect1"] / 2 - 70 * Math.cos(Math.PI * 2 / focusNumList.length * (index)) - 5
        let textArray = get_skill_name(focusNumList[index]).replace(/,/g, '').split(' ')
        vector_text(svg, vectorTextX, vectorTextY, textArray)
      }
      let path1 = "M " + (ClusterSvg['rect1'] + sampleSpace + sampleTransX) + " " + (ClusterSvg['height'] - ClusterSvg['rect1'] / 2 - sampleSpace / 2) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR + sampleTransX) + " " + (ClusterSvg['height'] - ClusterSvg['rect1'] / 2 - sampleSpace / 2) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR - sampleLength * Math.cos(Math.PI * 5 / 12) + sampleTransX) + " " +
              (ClusterSvg['height'] - ClusterSvg['rect1'] / 2 - sampleSpace / 2 - sampleR / 2) + " z",
          path2 = "M " + (ClusterSvg['rect1'] + sampleSpace + sampleTransX) + " " + (ClusterSvg['height'] - sampleSpace / 2) +
              " L " + (ClusterSvg['rect1'] * 2 - sampleSpace + sampleTransX) + " " + (ClusterSvg['height'] - sampleSpace / 2) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR * Math.cos(Math.PI / 6) + sampleTransX) + " " +//-
              (ClusterSvg['height'] - ClusterSvg['rect1'] / 2 + sampleSpace / 2) + " z",
          path2Inner1 = "M " + (ClusterSvg['rect1'] + sampleSpace + sampleTransX) + " " + (ClusterSvg['height'] - sampleSpace / 2) +
              " L " + (ClusterSvg["rect1"] + sampleSpace + sampleR * 8 / 30 - sampleLength * 8 / 30 * 0.3 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 8 / 30 * 0.3 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg["rect1"] + sampleSpace + sampleR * 11 / 30 - sampleLength * 11 / 30 * 0.4 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 11 / 30 * 0.4 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg["rect1"] + sampleSpace + sampleR * 14 / 30 - sampleLength * 14 / 30 * 0.55 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 14 / 30 * 0.55 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg["rect1"] + sampleSpace + sampleR * 17 / 30 - sampleLength * 17 / 30 * 0.65 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 17 / 30 * 0.65 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg["rect1"] + sampleSpace + sampleR * 20 / 30 - sampleLength * 20 / 30 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 20 / 30 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg["rect1"] + sampleSpace + sampleR * 26 / 30 - sampleLength * 26 / 30 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 26 / 30 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg["rect1"] + sampleSpace + sampleR * 28 / 30 - sampleLength * 28 / 30 * 0.2 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 28 / 30 * 0.2 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg["rect1"] + sampleSpace + sampleR * 30 / 30 + sampleTransX) + " " + (ClusterSvg['height'] - sampleSpace / 2) + " z",
          path2Inner2 = "M " + (ClusterSvg['rect1'] + sampleSpace + sampleR * 20 / 30 + sampleTransX) + " " + (ClusterSvg['height'] - sampleSpace / 2) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR * 21 / 30 - sampleLength * 21 / 30 * 0.3 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 21 / 30 * 0.3 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR * 22 / 30 - sampleLength * 22 / 30 * 0.15 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 22 / 30 * 0.15 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR * 25 / 30 - sampleLength * 25 / 30 * 0.55 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 25 / 30 * 0.55 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR * 26 / 30 + sampleTransX) + " " + (ClusterSvg['height'] - sampleSpace / 2) + " z",
          path2Inner3 = "M " + (ClusterSvg['rect1'] + sampleSpace + sampleR * 20 / 30 + sampleTransX) + " " + (ClusterSvg['height'] - sampleSpace / 2) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR * 20 / 30 - sampleLength * 20 / 30 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 20 / 30 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR * 26 / 30 - sampleLength * 26 / 30 * Math.cos(Math.PI * 5 / 12) + sampleTransX) +
              " " + (ClusterSvg['height'] - sampleSpace / 2 - sampleLength * 26 / 30 * Math.sin(Math.PI * 5 / 12)) +
              " L " + (ClusterSvg['rect1'] + sampleSpace + sampleR * 26 / 30 + sampleTransX) + " " + (ClusterSvg['height'] - sampleSpace / 2) + " z"

      svg.append("path").attr("d", path1).attr("stroke", radarColor["border"]).attr("stroke-width", 1 + 'px')
          .attr("fill", "#fbfbfb").attr("class", "rect2 Sample radar")
      svg.append("path").attr("d", path2).attr("stroke", radarColor["border"]).attr("stroke-width", 1 + 'px')
          .attr("fill", "#fbfbfb").attr("class", "rect2 Sample radar")
      svg.append("path").attr("d", path2Inner1).attr("stroke", radarColor["border"]).attr("stroke-width", 0.01 + 'px')
          .attr("fill", radarColor["level1"]).attr("class", "rect2 Sample radar")
      svg.append("path").attr("d", path2Inner3).attr("stroke", radarColor["border"]).attr("stroke-width", 0.01 + 'px')
          .attr("fill", radarColor["level2"]).attr("class", "rect2 Sample radar")
      svg.append("path").attr("d", path2Inner2).attr("stroke", radarColor["border"]).attr("stroke-width", 0.01 + 'px')
          .attr("fill", radarColor["level3"]).attr("class", "rect2 Sample radar")

      let path1X1 = ClusterSvg['rect1'] + sampleSpace + sampleTransX,
          path1Y1 = ClusterSvg['height'] - ClusterSvg['rect1'] / 2 - sampleSpace / 2//
      for (let i = 1; i < 5; i++) {
        svg.append("line").attr("x1", path1X1).attr("y1", path1Y1)
            .attr("x2", (ClusterSvg['rect1'] + sampleSpace + sampleR - sampleLength * 0.2 * i * Math.sin(Math.PI / 12) + sampleTransX))
            .attr("y2", (ClusterSvg['height'] - ClusterSvg['rect1'] / 2 - sampleSpace / 2 - sampleLength * 0.2 * i * Math.cos(Math.PI / 12)))//
            .attr("stroke", radarColor["border"]).attr("stroke-width", 0.2 + 'px')
            .attr("class", "rect2 Sample radar")
        svg.append("text").attr("font-size", 7).attr("text-anchor", "left")
            .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,0.4)")
            .attr("x", (ClusterSvg['rect1'] + sampleSpace + sampleR - sampleLength * 0.2 * i * Math.sin(Math.PI / 12) - 8 + sampleTransX))
            .attr("y", (ClusterSvg['height'] - ClusterSvg['rect1'] / 2 - sampleSpace / 2 - sampleLength * 0.2 * i * Math.cos(Math.PI / 12) + 8))
            .attr("class", "rect2 Sample radar")
            .text(i * 4)
      }
      svg.append("text").attr("font-size", 7).attr("text-anchor", "left")
          .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,0.4)")
          .attr("x", (ClusterSvg['rect1'] + sampleSpace + sampleR - sampleLength * Math.sin(Math.PI / 12) - 7.5 + sampleTransX))
          .attr("y", (ClusterSvg['height'] - ClusterSvg['rect1'] / 2 - sampleSpace / 2 - sampleLength * Math.cos(Math.PI / 12) + 11))
          .attr("class", "rect2 Sample radar")
          .text("20")
      svg.append("text").attr("font-size", 8).attr("text-anchor", "left")
          .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,0.6)")
          .attr("x", (path1X1 - 3.5))
          .attr("y", (path1Y1 + 8))
          .attr("class", "rect2 Sample radar")
          .text("0")
      // svg.append("rect").attr("x",(path1X1)).attr("y",(path1Y1-8)).attr("width","1px").attr("height","8px")
      //     .attr("fill","rgba(0,0,0,0.6)").attr("class","rect2 Sample radar")
      svg.append("rect").attr("x", (path1X1 + sampleR / 20)).attr("y", (path1Y1 - sampleR / 20 * Math.tan(Math.PI / 6))).attr("width", "1px").attr("height", (sampleR / 20 * Math.tan(Math.PI / 6)) + "px")
          .attr("fill", "rgba(0,0,0,0.6)").attr("class", "rect2 Sample radar")
      svg.append("text").attr("font-size", 8).attr("text-anchor", "left")
          .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,0.6)")
          .attr("x", (path1X1 + sampleR / 20 - 3.5))
          .attr("y", (path1Y1 + 8))
          .attr("class", "rect2 Sample radar")
          .text("0.05")
      svg.append("text").attr("font-size", 8).attr("text-anchor", "left")
          .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,0.6)")
          .attr("x", (path1X1 + sampleR - 3.5))
          .attr("y", (path1Y1 + 8))
          .attr("class", "rect2 Sample radar")
          .text(Math.ceil(radarColor["disLimit"]))
      svg.append("text").attr("font-size", 8).attr("text-anchor", "middle")
          .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,0.6)")
          .attr("transform", "translate(" +
              (path1X1 + sampleR - sampleLength / 2 * Math.sin(Math.PI / 12) - 7.5 + 10) + "," +
              (path1Y1 - sampleLength / 2 * Math.cos(Math.PI / 12)) + ") rotate(75)")
          .attr("x", 0)
          .attr("y", 0)
          .attr("class", "rect2 Sample radar")
          .text("Posts num")
      svg.append("text").attr("font-size", 8).attr("text-anchor", "middle")
          .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,0.6)")
          .attr("x", (path1X1 + sampleR / 2))
          .attr("y", (path1Y1 + 8))
          .attr("class", "rect2 Sample radar")
          .text("Skill vector value")
      let level = ['1-20', '21-40', '41-60', '61-80', '81-100', '101-120', '121-140', '141-160', '161-180', '181-200']
      let sampleHeight = (ClusterSvg["rect1"] - sampleSpace) / 10
      for (let i = 1; i <= 10; i++) {
        svg.append('rect').attr("x", (ClusterSvg['rect1'] * 2 - 10 + sampleTransX) + "px")
            .attr("y", (ClusterSvg["height"] - ClusterSvg["rect1"] + sampleSpace / 2 + sampleHeight * (i - 1)) + "px")
            .attr("width", 10 + "px").attr("height", sampleHeight + "px")
            .attr("rx", (0) + "px").attr("ry", (0) + "px").attr("fill", radarColor["level" + (i)])
            .attr("stroke", "rgba(33, 37, 41, 0.1)").attr("class", "rect2").attr("id", "sampleLevel_" + (i))
        svg.append("text").attr("font-size", 7).attr("text-anchor", "left")
            .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(33, 37, 41, 0.4)")
            .attr("x", (ClusterSvg['rect1'] * 2 - 10 + 13 + sampleTransX) + "px")
            .attr("y", (ClusterSvg["height"] - ClusterSvg["rect1"] + sampleSpace / 2 + sampleHeight * (i - 0.5) + 3) + "px")
            .attr("class", "rect2 sampleLevelText")
            .text(level[(i - 1)])
      }
      let fillColor = this.jobColor.substring(0, this.jobColor.length - 2) + "1)"
      svg.append("circle").attr("cx", (ClusterSvg['rect1'] * 2 + 45 + sampleTransX) + "px")
          .attr("cy", (ClusterSvg['height'] - ClusterSvg['rect1'] / 2 - 80) + "px")
          .attr("r", (5) + "px").attr("fill", fillColor)
          .attr("stroke", this.jobColor).attr("stroke-width", '1px')
          .attr("class", "rect2").attr("id", "samplePost_" + (0))
      svg.append("text").attr("font-size", 10).attr("text-anchor", "left")
          .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(33, 37, 41, 0.5)")
          .attr("x", (ClusterSvg['rect1'] * 2 + 45 + 10 + sampleTransX) + "px")
          .attr("y", (ClusterSvg['height'] - ClusterSvg['rect1'] / 2 - 80 + 4) + "px")
          .attr("class", "rect2 samplePost")
          .text(translate_post_name(that.jobPost))


      svg.append("text").attr("font-size", 12).attr("text-anchor", "left")
          .style("font-family", "sans-serif").style("font-weight", "350").style("fill", radarColor["buttonOut"])
          .attr("x", 5 + "px")
          .attr("y", (ClusterSvg["height"] - ClusterSvg["rect1"] + 12) + "px")
          .attr("class", "radar Clusters").attr("id", "ClustersButton")
          .text("Clusters")
          .on("mouseover", function () {
            d3.select("#" + this.id).style("fill", radarColor["buttonOver"])
          })
          .on("mouseout", function () {
            d3.select("#" + this.id).style("fill", radarColor["buttonOut"])
          })
          .on("click", function () {
            d3.selectAll(".radar").remove()
            that.create_clusters()
          })
      // svg.append("line").attr("x1",startX+"px").attr("x2",startX+"px")
      //     .attr("y1",0).attr("y2",(ClusterSvg["height"]-ClusterSvg["rect1"])+"px")
      //     .attr("stroke",radarColor["line"]).attr("class","radar backLine")
      // svg.append("line").attr("x1",ClusterSvg["rect1"]+"px").attr("x2",(ClusterSvg["width"])+"px")
      //     .attr("y1",ClusterSvg["height"]-ClusterSvg["rect1"]+"px").attr("y2",ClusterSvg["height"]-ClusterSvg["rect1"]+"px")
      //     .attr("stroke",radarColor["line"]).attr("class","radar backLine")

      let border = 1
      let xNum = 0, xIndex = 0
      for (const eleX in this.jobAxisX) {
        xNum += 1
      }
      for (const eleX in this.jobAxisX) {
        this.jobRectStartX[eleX] = startX
        xIndex += 1
        svg.append("rect").attr("x", startX + border + "px").attr("y", (ClusterSvg["height"] - ClusterSvg["rect1"] - 20 + border) + "px")
            .attr("width", this.jobAxisX[eleX] / this.jobs.length * (ClusterSvg["width"] - 20 - 60 * xNum) + 60 - 2 * border)
            .attr("height", 20 - 2 * border + "px")
            .attr("fill", radarColor["backOff"]).attr("class", "radar backTagBack " + translate_company_type(eleX).replace("(", "_").replace(")", "_").replace("&", "_"))
        svg.append("text").attr("font-size", 12).attr("text-anchor", "left")
            .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,1)")
            .attr("x", 0 + "px")
            .attr("y", 0 + "px")
            .attr("class", "radar backTag")
            .attr("transform", "translate(" + (startX + 5) + "," + (ClusterSvg["height"] - ClusterSvg["rect1"] - 5) + ") rotate(0)")//;
            .text(translate_company_type(eleX))
        if (Math.abs(startX + this.jobAxisX[eleX] / this.jobs.length *
            (ClusterSvg["width"] - 20 - 60 * xNum) + xIndex * 60 - ClusterSvg["width"]) > 1) {
          startX += this.jobAxisX[eleX] / this.jobs.length * (ClusterSvg["width"] - 20 - 60 * xNum) + 60
          // svg.append("line").attr("x1",startX+"px").attr("x2",startX+"px")
          //     .attr("y1",0).attr("y2",ClusterSvg["height"]-ClusterSvg["rect1"]+"px")
          //     .attr("stroke",radarColor["line"]).attr("class","radar backLine")
        }
      }
      let yNum = 0, yIndex = 0
      for (const eleY in this.jobAxisY) {
        yNum += 1
      }
      for (const eleY in this.jobAxisY) {
        this.jobRectStartY[eleY] = startY
        yIndex += 1
        svg.append("rect").attr("x", border + "px").attr("y", (startY + border) + "px")
            .attr("width", 20 - 2 * border)
            .attr("height", (this.jobAxisY[eleY] / this.jobs.length * (ClusterSvg["height"] - ClusterSvg["rect1"] - 60 * yNum - 20) + 60 - 2 * border) + "px")
            .attr("fill", radarColor["backOff"]).attr("class", "radar backTagBack s" + eleY.replace("-", '_').replace('>', '_').replace('<', '_'))
        svg.append("text").attr("font-size", 12).attr("text-anchor", "left")
            .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,1)")
            .attr("x", 0 + "px")
            .attr("y", 0 + "px")
            .attr("transform", "translate(" + (3) + "," + (startY + 5) + ") rotate(90)")
            .attr("class", "radar backTag")
            .text(eleY)
        if (Math.abs(startY + this.jobAxisY[eleY] / this.jobs.length *
            (ClusterSvg["height"] - ClusterSvg["rect1"] - 20 - 60 * yNum) + yIndex * 60 -
            (ClusterSvg["height"] - ClusterSvg["rect1"] - 20)) > 1) {
          startY += this.jobAxisY[eleY] / this.jobs.length * (ClusterSvg["height"] - ClusterSvg["rect1"] - 60 * yNum - 20) + 60
          // svg.append("line").attr("x1",0).attr("x2",ClusterSvg["width"]+"px").attr("y1",startY+"px").attr("y2",startY+"px")
          //     .attr("stroke",radarColor["line"]).attr("class","radar backLine")
        }
      }

      //贴蒙版
      for (const eleX in this.jobAxisX) {
        for (const eleY in this.jobAxisY) {
          let length = this.jobAxisX[eleX] / this.jobs.length * (ClusterSvg["width"] - 20 - 60 * xNum) + 60,
              width = this.jobAxisY[eleY] / this.jobs.length * (ClusterSvg["height"] - ClusterSvg["rect1"] - 60 * yNum - 20) + 60
          svg.append("rect").attr("x", this.jobRectStartX[eleX] + border + "px").attr("y", this.jobRectStartY[eleY] + border + "px")
              .attr("width", length - 2 * border + "px").attr("height", width - 2 * border + "px")
              .attr("fill", radarColor["backOff"])
              .attr("class", "radar backTagBack s" +
                  eleY.replace("-", '_').replace('>', '_').replace('<', '_')
                  + " " + translate_company_type(eleX).replace("(", "_").replace(")", "_").replace("&", "_"))
        }
      }

      document.querySelectorAll(".radar").forEach(radar => {
        radar.onmousemove = function () {
          d3.selectAll(".averageCluster").remove()
        }
      })

      document.querySelectorAll(".backTagBack").forEach(back => {
        let classList = back.getAttribute("class").split(' ')
        back.onmouseover = function () {
          for (let i = 2; i < classList.length; i++) {
            document.querySelectorAll("." + classList[i]).forEach(back2 => {
              back2.setAttribute("fill", radarColor["backOn"])
            })
          }
        }
        back.onmouseout = function () {
          for (let i = 2; i < classList.length; i++) {
            document.querySelectorAll("." + classList[i]).forEach(back2 => {
              back2.setAttribute("fill", radarColor["backOff"])
            })
          }
        }
      })

      let numList = get_vectors_list(this.focusCluster, 8)
      let modelList = []
      for (let i = 0; i < 28; i++) {
        modelList.push(i)
      }
      // console.log('numList',numList,'jobs_length',this.jobs.length)
      for (const enttype in this.jobRectRadarAxisBox) {
        for (const salary in this.jobRectRadarAxisBox[enttype]) {
          let length = this.jobAxisX[enttype] / this.jobs.length * (ClusterSvg["width"] - 20 - 60 * xNum) + 60,
              width = this.jobAxisY[salary] / this.jobs.length * (ClusterSvg["height"] - ClusterSvg["rect1"] - 60 * yNum - 20) + 60,
              radarNum = this.jobRect[enttype][salary],
              n = get_radar_r(length, width, radarNum)
          this.jobRectRadarAxisBox[enttype][salary] = get_radar_axis(radarNum, n, length, width
              , this.jobRectStartX[enttype], this.jobRectStartY[salary])
        }
      }
      for (const job of this.jobs) {
        // let unit=(ClusterSvg["height"]-ClusterSvg["rect1"])/this.jobs.length/2
        let length = this.jobAxisX[job["enttype"]] / this.jobs.length * (ClusterSvg["width"] - 20 - 60 * xNum) + 60
        let width = this.jobAxisY[job["salary"]] / this.jobs.length * (ClusterSvg["height"] - ClusterSvg["rect1"] - 60 * yNum - 20) + 60
        let radarNum = this.jobRect[job["enttype"]][job["salary"]]
        let n = get_radar_r(length, width, radarNum)
        let R = n
        this.jobRectRadarRealNum[job["enttype"]][job["salary"]] += 1
        // console.log('jobRealRadarNum',this.jobRectRadarRealNum[job["enttype"]][job["salary"]],
        // 'enttype',job['enttype'],'salary',job['salary'])
        let axis = this.jobRectRadarAxisBox[job["enttype"]][job["salary"]][this.jobRectRadarRealNum[job["enttype"]][job["salary"]] - 1]
        //console.log(axis,'axis',this.jobRectRadarRealNum[job["enttype"]][job["salary"]],radarNum,job["enttype"],job["salary"])
        let radarData = get_radar_data(job["skill_vector"], [], numList)
        // console.log('axis',axis)
        cluster_radar(svg, axis[0], axis[1], R, radarData["vectors"], [], numList,
            this.jobColor, job["document_id"], "job")
      }
      cluster_radar(svg, ClusterSvg["rect1"] / 2, ClusterSvg["height"] - ClusterSvg["rect1"] / 2, 80,
          this.focusCluster, this.focusDistribution, modelList, this.jobColor, "focusCluster", "focusCluster")
      svg.append("text").attr("font-size", 12).attr("text-anchor", "left")
          .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(33, 37, 41, 0.5)")
          .attr("x", ((0) + 6) + "px")
          .attr("y", (ClusterSvg['height'] - 6) + "px")
          .attr("class", "focusCluster radar")
          .text("ClusterID:" + this.focusClusterID)
      //为job雷达图添加点击事件：生成雷达大图、并且更新Detail视图的信息，生成恢复focusCluster的按钮
      document.querySelectorAll(".job").forEach(job => {
        let that = this
        let ID = job.id.split("_")[1]
        job.onclick = function () {
          // if (that.leftID === "") {
          //   that.leftID = ID
          //   pipeService.$emit("id1", that.leftID)
          // } else {
          //   that.leftID = ""
          //   pipeService.$emit("id1", that.leftID)
          // }
          that.leftID = ID
          pipeService.$emit("id1", that.leftID)

          d3.selectAll(".focusJob").remove()
          d3.selectAll(".Cluster").remove()
          d3.selectAll(".focusCluster").remove()
          for (const job of that.jobs) {
            if (job["document_id"] === ID) {
              cluster_radar(svg, ClusterSvg["rect1"] / 2, ClusterSvg["height"] - ClusterSvg["rect1"] / 2,
                  80, job["skill_vector"], [], modelList, that.jobColor,
                  "focusJob", "focusJob")
              svg.append("text").attr("font-size", 12).attr("text-anchor", "left")
                  .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(33, 37, 41, 0.5)")
                  .attr("x", ((0) + 6) + "px")
                  .attr("y", (ClusterSvg['height'] - 6) + "px")
                  .attr("class", "focusJob radar")
                  .text("JobID:" + ID.substring(0, 20))
              svg.append("text").attr("font-size", 12).attr("text-anchor", "left")
                  .style("font-family", "sans-serif").style("font-weight", "350").style("fill", radarColor["buttonOut"])
                  .attr("x", (ClusterSvg["rect1"] - 50) + "px")
                  .attr("y", (ClusterSvg["height"] - ClusterSvg["rect1"] + 12) + "px")
                  .attr("class", "radar Cluster").attr("id", "ClusterButton")
                  .text("Cluster")
                  .on("mouseover", function () {
                    d3.select("#" + this.id).style("fill", radarColor["buttonOver"])
                  })
                  .on("mouseout", function () {
                    d3.select("#" + this.id).style("fill", radarColor["buttonOut"])
                  })
                  .on("click", function () {
                    d3.selectAll(".focusJob").remove()
                    d3.selectAll(".Cluster").remove()
                    cluster_radar(svg, ClusterSvg["rect1"] / 2, ClusterSvg["height"] - ClusterSvg["rect1"] / 2,
                        80, that.focusCluster, that.focusDistribution, modelList, that.jobColor,
                        "focusCluster", "focusCluster")
                    svg.append("text").attr("font-size", 12).attr("text-anchor", "left")
                        .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(33, 37, 41, 0.5)")
                        .attr("x", ((0) + 6) + "px")
                        .attr("y", (ClusterSvg['height'] - 6) + "px")
                        .attr("class", "focusCluster radar")
                        .text("ClusterID:" + that.focusClusterID)
                  })
              break
            }
          }
        }
        job.oncontextmenu = function () {
          if (that.rightID === "") {
            that.rightID = ID
            pipeService.$emit("id2", that.rightID)
          } else {
            that.rightID = ""
            pipeService.$emit("id2", that.rightID)
          }

          d3.selectAll(".focusJob").remove()
          d3.selectAll(".Cluster").remove()
          d3.selectAll(".focusCluster").remove()
          for (const job of that.jobs) {
            if (job["document_id"] === ID) {
              cluster_radar(svg, ClusterSvg["rect1"] / 2,
                  ClusterSvg["height"] - ClusterSvg["rect1"] / 2, 80, job["skill_vector"],
                  [], modelList, that.jobColor, "focusJob", "focusJob")
              svg.append("text").attr("font-size", 12).attr("text-anchor", "left")
                  .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(33, 37, 41, 0.5)")
                  .attr("x", ((0) + 6) + "px")
                  .attr("y", (ClusterSvg['height'] - 6) + "px")
                  .attr("class", "focusJob radar")
                  .text("JobID:" + ID.substring(0, 20))
              svg.append("text").attr("font-size", 12).attr("text-anchor", "left")
                  .style("font-family", "sans-serif").style("font-weight", "350").style("fill", radarColor["buttonOut"])
                  .attr("x", (ClusterSvg["rect1"] - 50) + "px")
                  .attr("y", (ClusterSvg["height"] - ClusterSvg["rect1"] + 12) + "px")
                  .attr("class", "radar Cluster").attr("id", "ClusterButton")
                  .text("Cluster")
                  .on("mouseover", function () {
                    d3.select("#" + this.id).style("fill", radarColor["buttonOver"])
                  })
                  .on("mouseout", function () {
                    d3.select("#" + this.id).style("fill", radarColor["buttonOut"])
                  })
                  .on("click", function () {
                    d3.selectAll(".focusJob").remove()
                    d3.selectAll(".Cluster").remove()
                    cluster_radar(svg, ClusterSvg["rect1"] / 2, ClusterSvg["height"] - ClusterSvg["rect1"] / 2,
                        80, that.focusCluster, that.focusDistribution, modelList, that.jobColor,
                        "focusCluster", "focusCluster")
                    svg.append("text").attr("font-size", 12).attr("text-anchor", "left")
                        .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(33, 37, 41, 0.5)")
                        .attr("x", ((0) + 6) + "px")
                        .attr("y", (ClusterSvg['height'] - 6) + "px")
                        .attr("class", "focusCluster radar")
                        .text("ClusterID:" + that.focusClusterID)
                  })
              break
            }
          }
        }
        job.onmouseover = function () {
          if (job.id.split("_").length === 3 && job.id.split("_")[2] === 'back') {
            let d, legendX, legendY, R = 50, vectorLength
            d = job.getAttribute('d').split(" ")
            vectorLength = (d[11] - d[2]) / 2
            legendX = d[1]
            legendY = parseInt(d[2]) + vectorLength
            legendX = legendX - (legendX - ClusterSvg['width'] / 2) / Math.abs(legendX - ClusterSvg["width"] / 2) *
                (R + vectorLength) / Math.sqrt(2)
            legendY = legendY - (legendY - (ClusterSvg["height"] - ClusterSvg["rect1"]) / 2) /
                Math.abs(legendY - (ClusterSvg["height"] - ClusterSvg["rect1"]) / 2) * (R + vectorLength) / Math.sqrt(2)
            let jobData
            for (const jobOne of that.jobs) {
              if (jobOne['document_id'] === job.id.split("_")[1]) {
                jobData = jobOne
              }
            }
            let radarData = get_radar_data(jobData["skill_vector"], [], numList)
            cluster_radar(svg, legendX, legendY, R, radarData["vectors"], [], numList,
                that.jobColor, 'jobLegend', "jobLegend")
          }
        }
        job.onmouseout = function () {
          d3.selectAll('.jobLegend').remove()
        }
      })
    },
    random_color(ID) {
      let theColor
      theColor = postRadarColor[this.clusterColor.length + 1]
      this.clusterColor.push({"ID": ID, "color": theColor})
      return theColor
    },
    get_cluster_radius() {
      //n代表返回的半径，limit表示n的最大值
      //this.clusters.length代表clusters的数量（簇的数量）
      //ClusterSvg['width']代表画布的长，值为780
      //(ClusterSvg['height']-ClusterSvg['rect1'])这个计算式代表画布的高（宽），值为455

      let n = 0, limit = 40
      do {
        n += 1
      } while (4 * n * n * this.clusters.length < ClusterSvg['width'] * (ClusterSvg['height'] - ClusterSvg['rect1']))
      n -= 1
      if (n > limit) n = limit
      return n - 5
    }
  },
  mounted() {
    this.create_svg()
    pipeService.$on('data_to_middle', (data) => {
      this.postID = data["post_id"];
      this.locations = data["locations"];
      this.qualifications = data["qualifications"];
      this.industries = data["industries"];
      this.experiences = data["experiences"];
    })
  }
}
</script>

<style scoped>
#Middleview {
  position: absolute;
  width: 800px;
  height: 690px;
  top: 70px;
  left: 1230px;
  border: 2px solid rgba(255, 255, 255, 0);
  border-radius: 5px;
  background-color: #ffffff;
  cursor: default;
}

#ClustersView {
  position: absolute;
  /*width: 780px;*/
  /*height: 650px;*/
  top: 30px;
  left: 10px;
  display: inline-block;
  margin-top: 5px;
  /*border: 2px solid rgba(33, 37, 41, 0.5) ;*/
  /*!*border-radius: 5px;*!*/
  /*background-color: #ffffff;*/
  /*cursor: default;*/
}

h3 {
  display: inline;
  position: absolute;
}

.btn {
  display: inline;
  right: 10px;
  position: absolute;
}
</style>
