<template>
  <div id="copyright">
    <h5>{{this.msg}}</h5>
  </div>
</template>

<script>
    export default{
        name:"FootComponent",
        data(){
            return{
                msg: '© 2022',
            };
        }
    }
</script>

<style scoped>
#copyright{
  padding: 0;
  margin: 0;
  /*border-top: #000000 solid 2px;*/

  width: 100%;
  height: 25px;
  color: #000000;
  text-align: center;

  background-color: #ffffff;

}
</style>
