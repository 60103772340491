<template>
  <div id="app">

    <div id="nav">
      <Home></Home>
    </div>

    <div>
      <Overview></Overview>
<!--      <Middleview></Middleview>-->
      <MiddleviewCluster></MiddleviewCluster>
      <Details></Details>
    </div>
    <div id="footer">
      <FootComponent/>
    </div>

  </div>
</template>


<script>
    import FootComponent from './components/Footer'
    import Home from "./components/Home";
    import Overview from "./components/Overview";
    // import Middleview from "./components/Middleview";
    import MiddleviewCluster from "@/components/MiddleviewCluster";
    import Details from "./components/Details";

    export default {
      components: {
        FootComponent,
        Overview,
        Home,
        // Middleview,
        MiddleviewCluster,
        Details
      }
    }
</script>



<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
  height: 1080px;
  width: 2048px;
  background-color: #ECECEC;
}

#nav {
  position: absolute;
  height: 50px;
  padding: 5px;
  left: 0;
  top: 0;
  margin: 5px;
  width: 1920px;
}

h3 {
  text-align: left;
  left: 10px;
  padding-left: 10px;
}

#footer {
  position: absolute;
  top: 1125px;
  width: 100%;
}

</style>
