import * as d3 from "d3";
import {
    get_skill_bar_length,
    get_post_bar_length,
    get_text_length, get_skill_name
} from "@/common/tools_for_data";
import {
    skill_bar_color,
    post_bar_color,
    salary_bar_color,
    skill_link_color,
    // post_salary_link_color,
    skill_collinear_color,
    skill_post_link_color,
    WHITE, GRAY, d3_removeSVG,
    skill_text_fontSize,
    radarColor, ClusterSvg, bar_state
} from "@/common/svg-help";
import {createWordCloud} from "@/common/wordcloud";

export function skill_text(svg, text, x, y, level, skills = null) {
    let id = text.replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_")

    let textBlock = svg.append("text")
        .attr("font-size", skill_text_fontSize)
        .attr("x", x)
        .attr("y", y)
        .attr("text-anchor", "left")
        .style("font-family", "sans-serif")
        .style("font-weight", "350")
        .style("fill", "rgba(0,0,0,1)")
        .attr("class", "skill_text_" + level)
        .attr("id", "skill_text_" + id)
        .text(text)
    if (skills != null) {
        let keyWord = {}
        for (let i = 0; i < 28; i++) {
            if (skills[i]["name"] === text) {
                for (let j = 0; j < 5; j++) {
                    keyWord[skills[i]["keywords"][j][0]] = skills[i]["keywords"][j][1];
                }
                textBlock.on("mouseover", function () {
                    svg.append("rect")
                        .attr("x", x - 90)
                        .attr("y", y - 30)
                        .attr("width", 80)
                        .attr("height", 80)
                        .attr("rx", 3)
                        .attr("ry", 3)
                        .attr("stroke", GRAY[3])
                        .attr("fill", WHITE)
                        .attr("id", "wordcloud" + i);
                    createWordCloud(svg, keyWord, x - 90, y - 30, 80, 80, 10, "wordcloud" + i);
                })
                textBlock.on("mouseout", function () {
                    d3_removeSVG("#wordcloud" + i);
                });
                break;
            }
        }
    }
}

export function skill_link(svg, start_id, end_id) {
    start_id = "#skill_text_" + start_id.replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_")
    end_id = "#skill_text_" + end_id.replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_")
    let data = {
        source: {
            x: parseFloat(d3.select(start_id).attr("x")) + parseFloat(get_text_length(d3.select(start_id).text())) + 8,//
            y: parseFloat(d3.select(start_id).attr("y")) - 3
        },
        target: {
            x: parseFloat(d3.select(end_id).attr("x")),
            y: parseFloat(d3.select(end_id).attr("y")) - 3
        }
    }
    let link = d3.linkHorizontal().x(d => d.x).y(d => d.y)
    svg.append("path")
        .attr("d", link(data))
        .attr("fill", "none")
        .attr("stroke", skill_link_color["off"])
        .attr("stroke-width", 1)
        .attr("class", "skill_link_" + start_id.slice(12, start_id.length))
}

export function skill_bar(svg, information, dx) {
    let id = "#skill_text_" + information["name"].replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_")
    let skill_bar_length = get_skill_bar_length(information["num"])
    let x = dx - skill_bar_length
    //let total_num=0
    let y = parseFloat(d3.select(id).attr("y")) - 10
    let bias = parseFloat(bar_state["on"]["stroke-width"].split('px')[0])
    let coe = 0.75
    let ix = x + coe * bias, iy = y + coe * bias
    let pNum = 0
    for (let i = 1; i < 4; i++) {
        pNum += information["proficiency"][i]
    }
    svg.append("rect")
        .attr("width", skill_bar_length)
        .attr("height", 12)
        .attr("x", x)
        .attr("y", y)
        .attr("rx", 3)
        .attr("ry", 3)
        .attr("fill", "#fbfbfb")
        .attr("stroke-width", "1px")
        .attr("stroke", "#fbfbfb")
        .attr("id", "skill_bar_back_" + information["name"].replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_"))
        .attr("class", "skill_bar skill_bar_back")
    for (let i = 1; i < 4; i++) {
        let color = skill_bar_color[4-i]
        svg.append("rect")
            .attr("width", (skill_bar_length - 1 * bias) * information["proficiency"][i] / pNum)
            .attr("height", 12 - coe * 2 * bias)
            .attr("x", ix)
            .attr("y", iy)
            .attr("rx", 3)
            .attr("ry", 3)
            .attr("fill", color)
            .attr("stroke-width", "1px")
            .attr("stroke", "#fbfbfb")
            .attr("id", "skill_bar_proficiency_" + i + "_" + information["name"].replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_"))
            .attr("class", "skill_bar")
            .on("mouseover",function (){
                let key_id=this.id
                let key_text=information["proficiency"][i]/pNum
                svg.append("text")
                    .attr("font-size",12)
                    .attr("x",parseInt(d3.select("#"+key_id).attr("x")))
                    .attr("y",parseInt(d3.select("#"+key_id).attr("y"))-4)
                    .attr("text-anchor","left")
                    .style("font-family","sans-serif")
                    .style("font-weight","350")
                    .style("fill","rgba(0,0,0,1)")
                    .attr("class","skill_proficiency_percent")
                    .text((key_text*100).toFixed(2)+"%")
            })
            .on("mouseout",function(){
                d3.select(".skill_proficiency_percent").remove()
            })
        ix = ix + (skill_bar_length - coe * 2 * bias) * information["proficiency"][i] / pNum
    }
    // for (let i = 0; i < 5; i++) {
    //     svg.append("rect")
    //         .attr("width",information["keywords"][i][1]*skill_bar_length/total_num)
    //         .attr("height",12)
    //         .attr("x",x)
    //         .attr("y",y)
    //         .attr("rx",3)
    //         .attr("ry",3)
    //         .attr("fill",color)
    //         .attr("stroke-width","1px")
    //         .attr("stroke","#fbfbfb")
    //         .attr("id","skill_bar_keyword_"+information["name"].replace(/\s/g,"_").replace(/&/g,"_").replace(/\./g,"_")+"_"+i)
    //         .attr("class","skill_bar")
    //         .on("mouseover",function(){
    //             let key_id=this.id
    //             let key_text=information["keywords"][i][0]
    //             svg.append("text")
    //                 .attr("font-size",12)
    //                 .attr("x",parseInt(d3.select("#"+key_id).attr("x")))
    //                 .attr("y",parseInt(d3.select("#"+key_id).attr("y"))-4)
    //                 .attr("text-anchor","left")
    //                 .style("font-family","sans-serif")
    //                 .style("font-weight","350")
    //                 .style("fill","rgba(0,0,0,1)")
    //                 .attr("class","skill_keywords")
    //                 .text(key_text)
    //         })
    //         .on("mouseout",function(){
    //             d3.select(".skill_keywords").remove()
    //         })
    //     x+=information["keywords"][i][1]*skill_bar_length/total_num
    // }
}

export function skill_collinear(svg, information) {
    d3.selectAll(".skill_collinear").remove()
    let start_id = "#skill_text_" + information["name"].replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_")
    let max_num = 0
    for (let skill of information["cooccurrence"]) {
        if (max_num < skill[1]) max_num = skill[1]
    }
    let end_id_self = "#skill_bar_back_" + information["name"].replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_")
    let data_self = {
        source: {
            x: parseInt(d3.select(start_id).attr("x")) + parseFloat(d3.select(start_id).text().length) * 5.6,
            y: parseInt(d3.select(start_id).attr("y")) - 3
        },
        target: {
            x: parseInt(d3.select(end_id_self).attr("x")),
            y: parseInt(d3.select(end_id_self).attr("y")) + 6
        }
    }
    let link_self = d3.linkHorizontal().x(d => d.x).y(d => d.y)
    svg.append("path")
        .attr("d", link_self(data_self))
        .attr("fill", "none")
        .style("stroke", skill_collinear_color)
        .style("stroke-width", 1)
        .attr("class", "skill_collinear")
    for (let skill of information["cooccurrence"]) {
        let end_id = "#skill_bar_back_" + skill[0].replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_")
        let data = {
            source: {
                x: parseInt(d3.select(start_id).attr("x")) + parseFloat(d3.select(start_id).text().length) * 5.6,
                y: parseInt(d3.select(start_id).attr("y")) - 3
            },
            target: {
                x: parseInt(d3.select(end_id).attr("x")),
                y: parseInt(d3.select(end_id).attr("y")) + 6
            }
        }
        let link = d3.linkHorizontal().x(d => d.x).y(d => d.y)
        svg.append("path")
            .attr("d", link(data))
            .attr("fill", "none")
            .style("stroke", skill_collinear_color)
            .style("stroke-width", skill[1] / max_num)
            .attr("class", "skill_collinear")
    }
}

export function post_bar(svg, information, x, y) {
    let total_length = get_post_bar_length(information["num"])

    let technical_percent = parseFloat(information["skill-info"]["硬技能占比"]).toFixed(4)
    let foundational_percent = parseFloat(information["skill-info"]["软技能占比"]).toFixed(4)

    let bias = bar_state["on"]["stroke-width"].split('px')[0]

    svg.append("rect")
        .attr("width", total_length + 2 * bias)
        .attr("height", 10 + 2 * bias)
        .attr("x", x - bias)
        .attr("y", y - bias)
        .attr("rx", 3)
        .attr("ry", 3)
        .attr("fill", "#fbfbfb")
        .attr("stroke-width", "1px")
        .attr("stroke", "#fbfbfb")
        .attr("id", "post_bar_back_" + information["name"].replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_").replace(/\+/g, "_"))
        .attr("class", "post_bar post_bar_back")
    svg.append("rect")
        .attr("width", total_length * technical_percent)
        .attr("height", 10)
        .attr("x", x)
        .attr("y", y)
        .attr("rx", 3)
        .attr("ry", 3)
        .attr("fill", post_bar_color["technical"])
        .attr("stroke-width", "1px")
        .attr("stroke", "#fbfbfb")
        .attr("id", "post_technical_bar_" + information["name"].replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_").replace(/\+/g, "_"))
        .attr("class", "post_bar")
        .on("mouseover", function () {
            let id = this.id
            let float_x = d3.select("#" + id).attr("x")
            let float_y = d3.select("#" + id).attr("y")
            let percent = parseFloat(d3.select("#" + id).attr("width")) / parseFloat(d3.select("#post_bar_back_" + id.slice(19, id.length)).attr("width"))
            svg.append("rect")
                .attr("width", 84)
                .attr("height", 12)
                .attr("x", float_x)
                .attr("y", float_y - 12)
                .attr("rx", 3)
                .attr("ry", 3)
                .attr("fill", "#fbfbfb")
                .attr("stroke-width", "1px")
                .attr("stroke", "#fbfbfb")
                .attr("class", "post_float")
            svg.append("text")
                .attr("font-size", 10)
                .attr("x", float_x)
                .attr("y", float_y - 2)
                .attr("text-anchor", "left")
                .style("font-family", "sans-serif")
                .style("font-weight", "350")
                .style("fill", "rgba(0,0,0,1)")
                .attr("class", "post_float")
                .text("Technical:" + (percent * 100).toFixed(2) + "%")
        })
        .on("mouseout", function () {
            d3.selectAll(".post_float").remove()
        })
    svg.append("rect")
        .attr("width", total_length * foundational_percent)
        .attr("height", 10)
        .attr("x", x + total_length * technical_percent)
        .attr("y", y)
        .attr("rx", 3)
        .attr("ry", 3)
        .attr("fill", post_bar_color["foundational"])
        .attr("stroke-width", "1px")
        .attr("stroke", "#fbfbfb")
        .attr("id", "post_foundational_bar_" + information["name"].replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_").replace(/\+/g, "_"))
        .attr("class", "post_bar")
        .on("mouseover", function () {
            let id = this.id
            let float_x = d3.select("#" + id).attr("x")
            let float_y = d3.select("#" + id).attr("y")
            let percent = parseFloat(d3.select("#" + id).attr("width")) / parseFloat(d3.select("#post_bar_back_" + id.slice(22, id.length)).attr("width"))
            svg.append("rect")
                .attr("width", 108)
                .attr("height", 12)
                .attr("x", float_x)
                .attr("y", float_y - 12)
                .attr("rx", 3)
                .attr("ry", 3)
                .attr("fill", "#fbfbfb")
                .attr("stroke-width", "1px")
                .attr("stroke", "#fbfbfb")
                .attr("class", "post_float")
            svg.append("text")
                .attr("font-size", 10)
                .attr("x", float_x)
                .attr("y", float_y - 2)
                .attr("text-anchor", "left")
                .style("font-family", "sans-serif")
                .style("font-weight", "350")
                .style("fill", "rgba(0,0,0,1)")
                .attr("class", "post_float")
                .text("Foundational:" + (percent * 100).toFixed(2) + "%")
        })
        .on("mouseout", function () {
            d3.selectAll(".post_float").remove()
        })

    svg.append("text")
        .attr("font-size", 10)
        .attr("x", x + 250)
        .attr("y", y + 8)
        .attr("text-anchor", "end")
        .style("font-family", "sans-serif")
        .style("font-weight", "350")
        .style("fill", "rgba(0,0,0,1)")
        .attr("class", "post_name")
        .attr("id", "post_name_of_" + information["name"].replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_").replace(/\+/g, "_"))
        .text(information["name"])
}

export function skill_post_link(svg, skill_name, post_name, num) {
    let skill_id = skill_name.replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_")
    let post_id = post_name.replace(/\s/g, "_").replace(/&/g, "_").replace(/\./g, "_").replace(/\+/g, "_")
    if (document.querySelectorAll("." + skill_id + ",." + post_id) !== []) {
        let data = {
            source: {
                x: parseInt(d3.select("#skill_bar_back_" + skill_id).attr("x")) + parseInt(d3.select("#skill_bar_back_" + skill_id).attr("width")),
                y: parseInt(d3.select("#skill_bar_back_" + skill_id).attr("y")) + 6
            },
            target: {
                x: parseInt(d3.select("#post_bar_back_" + post_id).attr("x")),
                y: parseInt(d3.select("#post_bar_back_" + post_id).attr("y")) + 5
            }
        }
        let link = d3.linkHorizontal().x(d => d.x).y(d => d.y)
        svg.append("path")
            .attr("d", link(data))
            .attr("fill", "none")
            .style("stroke", skill_post_link_color)
            .style("stroke-width", num * 2)
            .attr("class", skill_id + " " + post_id + " skill_post_link")
    }
}

export function salary_bar(svg, name, length, salary_info, x, y, id) {
    let total_length = length
    let bias = bar_state["on"]["stroke-width"].split('px')[0]

    svg.append("text")
        .attr("font-size", 11)
        .attr("x", x + 250)
        .attr("y", y + 10)
        .attr("text-anchor", "end")
        .style("font-family", "sans-serif")
        .style("font-weight", "300")
        .style("fill", "rgba(0,0,0,1)")
        .attr("class", "salary_name")
        .attr("id", "salary_name_of_" + id)
        .text(name)

    svg.append("rect")
        .attr("width", total_length + 2 * bias)
        .attr("height", 10 + 2 * bias)
        .attr("x", x - bias)
        .attr("y", y - bias)
        .attr("rx", 3)
        .attr("ry", 3)
        .attr("fill", "#fbfbfb")
        .attr("stroke-width", "1px")
        .attr("stroke", "#fbfbfb")
        .attr("id", "salary_bar_back_" + id)
        .attr("class", "salary_bar salary_bar_back")

    let now_x = x
    for (let salary of salary_info) {
        let color
        let level
        if (salary[0] === "0-5000") level = 1
        else if (salary[0] === "5000-10000") level = 2
        else if (salary[0] === "10000-15000") level = 3
        else if (salary[0] === "15000-20000") level = 4
        else if (salary[0] === "20000-30000") level = 5
        else if (salary[0] === "over 30000") level = 6
        color = salary_bar_color[level]

        svg.append("rect")
            .attr("width", total_length * salary[1])
            .attr("height", 10)
            .attr("x", now_x)
            .attr("y", y)
            .attr("rx", 3)
            .attr("ry", 3)
            .attr("fill", color)
            .attr("stroke-width", "1px")
            .attr("stroke", "#fbfbfb")
            .attr("id", "salary_bar_section_" + id + "_" + level)
            .attr("class", "salary_bar")
            .on("mouseover", function () {
                let the_id = this.id
                let the_x = d3.select("#" + the_id).attr("x")
                let the_y = d3.select("#" + the_id).attr("y")
                let the_level = the_id.split("_")[4]
                let the_text
                if (the_level === "1") the_text = "0-5k:"
                else if (the_level === "2") the_text = "5-10k:"
                else if (the_level === "3") the_text = "10-15k:"
                else if (the_level === "4") the_text = "15-20k:"
                else if (the_level === "5") the_text = "20-30k:"
                else if (the_level === "6") the_text = "30k over:"

                let back_id = the_id.split("_")[3]
                let the_width = d3.select("#" + the_id).attr("width")
                let back_width = d3.select("#salary_bar_back_" + back_id).attr("width")
                the_text += ((parseFloat(the_width) / parseFloat(back_width)) * 100).toFixed(2) + "%"

                svg.append("text")
                    .attr("font-size", 10)
                    .attr("x", +the_x + 2)
                    .attr("y", +the_y - 2)
                    .attr("text-anchor", "left")
                    .style("font-family", "sans-serif")
                    .style("font-weight", "350")
                    .style("fill", "rgba(0,0,0,1)")
                    .attr("class", "salary_float")
                    .text(the_text)
            })
            .on("mouseout", function () {
                d3.selectAll(".salary_float").remove()
            })
        now_x += total_length * salary[1]
    }

    // document.querySelectorAll(".post_bar").forEach(bar=>{
    //     if (bar.getAttribute("name")==="on") {
    //         let id="#post_name_of_"+bar.id.slice(14,bar.id.length)
    //         let post=document.querySelector(id)
    //         let data={
    //             source:{
    //                 x:parseFloat(post.getAttribute("x"))+3,//
    //                 y:parseFloat(post.getAttribute("y"))-3
    //             },
    //             target:{
    //                 x:x,
    //                 y:y+5
    //             }
    //         }
    //         let link=d3.linkHorizontal().x(d=>d.x).y(d=>d.y)
    //         svg.append("path")
    //             .attr("d",link(data))
    //             .attr("fill","none")
    //             .style("stroke",post_salary_link_color)
    //             .style("stroke-width",0.5)
    //             .attr("class","post_salary_link")
    //     }
    // })
}

export function cluster_radar(svg, radarX, radarY, radarR, vectorDataM, DistributionData, numList, backColor, ID, type) {
    let vectorData = []
    let axisNum = vectorDataM.length
    // if (type==='focusJob')console.log(numList)
    for (let i = 0; i < axisNum; i++) {
        vectorData.push(0)
    }
    for (let i = 0; i < axisNum; i++) {
        vectorData[i] = vectorDataM[i]//Math.sqrt(Math.sqrt())
    }
    if (type !== "averageCluster" && type !== "focusCluster" && type !== "focusJob" && type !== "focusClusterTep"
        && type !== "sampleCluster" && type !== 'job' && type !== 'jobLegend') {//
        let pathData
        pathData = "M " + (radarX - Math.sin(0 / axisNum * 2 * Math.PI) * radarR) + " " +
            (radarY - Math.cos(0 / axisNum * 2 * Math.PI) * radarR)
        for (let i = 1; i < axisNum; i++) {
            pathData += " L " + (radarX - Math.sin(i / axisNum * 2 * Math.PI) * radarR) + " " +
                (radarY - Math.cos(i / axisNum * 2 * Math.PI) * radarR)
        }
        pathData += " z"
        if (type === 'Legend') {
            svg.append("path").attr("d", pathData).attr("fill", "#fbfbfb").attr("stroke", radarColor["backOff"])
                .attr("class", "radar " + type + " ID_" + ID).attr("id", "ID_" + ID + "_backBack")
        }
        // console.log(backColor,'backColor')
        let theBackColor = backColor.substring(0, backColor.length - 2) + "1)"
        svg.append("path").attr("d", pathData).attr("fill", theBackColor).attr("stroke", radarColor["backOff"])
            .attr("class", "radar " + type + " ID_" + ID).attr("id", "ID_" + ID + "_back")

        // .on("mouseover",function(){
        //     d3.select("#"+this.id).attr("fill",radarColor["backOn"])
        // })
        // .on("mouseout",function(){
        //     d3.select("#"+this.id).attr("fill",radarColor["backOff"])
        // })
    }
    if (type === 'cluster' || type === 'Legend' || type === 'focusCluster' || type === 'focusClusterTep') {// type==='averageCluster'||||type==='sampleCluster'
        let sideLength = radarR * Math.cos((Math.PI - 2 * Math.PI / axisNum) / 2) * 2
        for (let i = 0; i < axisNum; i++) {
            let data = DistributionData[i]
            let flag = 0, level = 0
            for (let j = 1; j < data.length; j++) {
                if (data[j] > level) {
                    flag = 1
                    break
                }
            }
            let angle = Math.PI - (Math.PI / 2 - i * 2 * Math.PI / axisNum) - (Math.PI - 2 * Math.PI / axisNum) / 2//i*2*Math.PI/axisNum
            let pathData
            while (flag) {
                pathData = "M " + radarX + ' ' + radarY
                for (let j = 0; j < data.length; j++) {
                    let ySideLength = sideLength * (j / (data.length - 1))
                    let yLength
                    if (data[j] <= level) {
                        yLength = 0
                    } else {
                        if (data[j] < (level + 20)) {
                            yLength = ySideLength * (data[j] - level) / 20
                        } else {
                            yLength = ySideLength
                        }
                    }
                    // console.log('data_x',data[j][0],'data_y',(data[j][1]-level)/100,'yLength',yLength/ySideLength,yLength)
                    //yLength=ySideLength*0.5
                    let x = radarX - Math.sin(i / axisNum * 2 * Math.PI) * radarR * (j / (data.length - 1))  //
                            - yLength * Math.cos(angle),
                        y = radarY - Math.cos(i / axisNum * 2 * Math.PI) * radarR * (j / (data.length - 1))//
                            + yLength * Math.sin(angle)

                    pathData += " L " + x + " " + y

                    if (j === data.length - 1) {
                        pathData += " L " + (radarX - Math.sin(i / axisNum * 2 * Math.PI) * radarR) + " " +
                            (radarY - Math.cos(i / axisNum * 2 * Math.PI) * radarR) + " z"
                    }
                    // console.log('yPercentage',yLength/ySideLength,'yLength',yLength,'ySideLength',ySideLength,'data',data[j]-level)
                    // svg.append("path").attr("d",pathData).attr("fill",radarColor["level"+(Math.floor(level/20)+1)]).attr("stroke",radarColor["link"])
                    //     .attr("stroke-width",0.01+'px').attr("class","radar "+type+" ID_"+ID)

                }
                // console.log('level',Math.floor(level/20)+1,'axisIndex',i,'color',color)
                svg.append("path").attr("d", pathData).attr("fill", radarColor["level" + (Math.floor(level / 20) + 1)])
                    .attr("stroke", radarColor["link"]).attr("stroke-width", 0.01 + 'px')
                    .attr("class", "radar " + type + " ID_" + ID)
                flag = 0
                level += 20
                for (let j = 1; j < data.length; j++) {
                    if (data[j] > level) {
                        flag = 1
                        break
                    }
                }
            }
        }
    }
    if (type !== 'job' && type !== 'jobLegend') {
        for (let i = 0; i < axisNum; i++) {
            // if (type==='focusJob') console.log('focusJob','color',backColor)
            svg.append("line")
                .attr("x1", (radarX - Math.sin(i / axisNum * 2 * Math.PI) * radarR) + "px")
                .attr("y1", (radarY - Math.cos(i / axisNum * 2 * Math.PI) * radarR) + "px")
                .attr("x2", (radarX - Math.sin((i + 1) / axisNum * 2 * Math.PI) * radarR) + "px")
                .attr("y2", (radarY - Math.cos((i + 1) / axisNum * 2 * Math.PI) * radarR) + "px")
                .attr("stroke", backColor).attr("class", "radar " + type + " ID_" + ID)//radarColor["border"]
            svg.append("line")
                .attr("x1", (radarX - Math.sin(i / axisNum * 2 * Math.PI) * radarR) + "px")
                .attr("y1", (radarY - Math.cos(i / axisNum * 2 * Math.PI) * radarR) + "px")
                .attr("x2", (radarX) + "px")
                .attr("y2", (radarY) + "px")
                .attr("stroke", backColor).attr("stroke-width", 0.1 + 'px')
                .attr("class", "radar " + type + " ID_" + ID)//radarColor["border"]
        }
    }
    let pointX, pointY
    if (type === "cluster" || type === 'Legend') {
        for (let i = 0; i < axisNum; i++) {
            if (i === 0) {
                pointX = (radarX - Math.sin(i / axisNum * 2 * Math.PI) * radarR * (vectorData[i] / radarColor["limit"]))
                pointY = (radarY - Math.cos(i / axisNum * 2 * Math.PI) * radarR * (vectorData[i] / radarColor["limit"]))

                svg.append("circle").attr("cx", pointX + "px").attr("cy", pointY + "px").attr("r", "2px").attr("fill", radarColor["point"])
                    .attr("class", "radar " + type + " ID_" + ID).attr("id", "ID_" + ID + "_" + i)
                    .on("mouseover", function () {
                        let id = this.id
                        let textAnchor = 'start'
                        if (parseInt(d3.select("#" + id).attr("cx")) > ClusterSvg['width'] / 2) textAnchor = 'end'
                        let trans = 6
                        if (parseInt(d3.select("#" + id).attr("cy")) > (ClusterSvg['height'] - ClusterSvg["rect1"]) / 2) trans = -6
                        svg.append("text").attr("font-size", 12).attr("text-anchor", textAnchor)
                            .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,1)")
                            .attr("x", parseInt(d3.select("#" + id).attr("cx")) + trans)
                            .attr("y", parseInt(d3.select("#" + id).attr("cy")) + trans)
                            .attr("class", "radarPointText")
                            .text(get_skill_name(numList[parseInt(id.split('_')[2])]) + ":" +
                                Math.ceil(Math.pow(vectorData[parseInt(id.split('_')[2])], 4) * 10000) / 100 + "%")
                    })
                    .on("mouseout", function () {
                        d3.selectAll(".radarPointText").remove()
                    })
            } else {
                let x = (radarX - Math.sin(i / axisNum * 2 * Math.PI) * radarR * (vectorData[i] / radarColor["limit"])),
                    y = (radarY - Math.cos(i / axisNum * 2 * Math.PI) * radarR * (vectorData[i] / radarColor["limit"]))

                svg.append("circle").attr("cx", x + "px").attr("cy", y + "px").attr("r", "2px").attr("fill", radarColor["point"])
                    .attr("class", "radar " + type + " ID_" + ID).attr("id", "ID_" + ID + "_" + i)
                    .on("mouseover", function () {
                        let id = this.id
                        let textAnchor = 'start'
                        if (parseInt(d3.select("#" + id).attr("cx")) > ClusterSvg['width'] / 2) textAnchor = 'end'
                        let trans = 6
                        if (parseInt(d3.select("#" + id).attr("cy")) > (ClusterSvg['height'] - ClusterSvg["rect1"]) / 2) trans = -6
                        svg.append("text").attr("font-size", 12).attr("text-anchor", textAnchor)
                            .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,1)")
                            .attr("x", parseInt(d3.select("#" + id).attr("cx")) + trans)
                            .attr("y", parseInt(d3.select("#" + id).attr("cy")) + trans)
                            .attr("class", "radarPointText")
                            .text(get_skill_name(numList[parseInt(id.split('_')[2])]) + ":" +
                                Math.ceil(Math.pow(vectorData[parseInt(id.split('_')[2])], 4) * 10000) / 100 + "%")
                    })
                    .on("mouseout", function () {
                        d3.selectAll(".radarPointText").remove()
                    })

                svg.append("line").attr("x1", x).attr("x2", pointX).attr("y1", y).attr("y2", pointY).attr("stroke", radarColor["link"])
                    .attr("class", "radar " + type + " ID_" + ID)

                pointX = x
                pointY = y
            }
        }
        for (let i = 0; i < axisNum; i++) {
            let x = (radarX - Math.sin(i / axisNum * 2 * Math.PI) * radarR * (vectorData[i] / radarColor["limit"])),
                y = (radarY - Math.cos(i / axisNum * 2 * Math.PI) * radarR * (vectorData[i] / radarColor["limit"]))

            svg.append("line").attr("x1", x).attr("x2", pointX).attr("y1", y).attr("y2", pointY).attr("stroke", radarColor["link"])
                .attr("class", "radar " + type + " ID_" + ID)
            break
        }
    } else {
        let pathData = ""
        for (let i = 0; i < axisNum; i++) {
            if (i === 0) {
                pointX = (radarX - Math.sin(i / axisNum * 2 * Math.PI) * radarR * (vectorData[i] / radarColor["limit"]))
                pointY = (radarY - Math.cos(i / axisNum * 2 * Math.PI) * radarR * (vectorData[i] / radarColor["limit"]))
                pathData += "M " + pointX + " " + pointY

                svg.append("circle").attr("cx", pointX + "px").attr("cy", pointY + "px").attr("r", "2px").attr("fill", radarColor["point"])
                    .attr("class", "radar " + type + " ID_" + ID).attr("id", "ID_" + ID + "_" + i)
                    .on("mouseover", function () {
                        let id = this.id
                        let textAnchor = 'start'
                        if (parseInt(d3.select("#" + id).attr("cx")) > ClusterSvg['width'] / 2) textAnchor = 'end'
                        let trans = 6
                        if (parseInt(d3.select("#" + id).attr("cy")) > (ClusterSvg['height'] - ClusterSvg["rect1"]) / 2) trans = -6
                        svg.append("text").attr("font-size", 12).attr("text-anchor", textAnchor)
                            .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,1)")
                            .attr("x", parseInt(d3.select("#" + id).attr("cx")) + trans)
                            .attr("y", parseInt(d3.select("#" + id).attr("cy")) + trans)
                            .attr("class", "radarPointText")
                            .text(get_skill_name(numList[parseInt(id.split('_')[2])]) + ":" +
                                Math.ceil(Math.pow(vectorData[parseInt(id.split('_')[2])], 4) * 10000) / 100 + "%")
                    })
                    .on("mouseout", function () {
                        d3.selectAll(".radarPointText").remove()
                    })
            } else {
                let x = (radarX - Math.sin(i / axisNum * 2 * Math.PI) * radarR * (vectorData[i] / radarColor["limit"])),
                    y = (radarY - Math.cos(i / axisNum * 2 * Math.PI) * radarR * (vectorData[i] / radarColor["limit"]))

                svg.append("circle").attr("cx", x + "px").attr("cy", y + "px").attr("r", "2px").attr("fill", radarColor["point"])
                    .attr("class", "radar " + type + " ID_" + ID).attr("id", "ID_" + ID + "_" + i)
                    .on("mouseover", function () {
                        let id = this.id
                        let textAnchor = 'start'
                        if (parseInt(d3.select("#" + id).attr("cx")) > ClusterSvg['width'] / 2) textAnchor = 'end'
                        let trans = 6
                        if (parseInt(d3.select("#" + id).attr("cy")) > (ClusterSvg['height'] - ClusterSvg["rect1"]) / 2) trans = -6
                        svg.append("text").attr("font-size", 12).attr("text-anchor", textAnchor)
                            .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(0,0,0,1)")
                            .attr("x", parseInt(d3.select("#" + id).attr("cx")) + trans)
                            .attr("y", parseInt(d3.select("#" + id).attr("cy")) + trans)
                            .attr("class", "radarPointText")
                            .text(get_skill_name(numList[parseInt(id.split('_')[2])]) + ":" +
                                Math.ceil(Math.pow(vectorData[parseInt(id.split('_')[2])], 4) * 10000) / 100 + "%")
                    })
                    .on("mouseout", function () {
                        d3.selectAll(".radarPointText").remove()
                    })

                svg.append("line").attr("x1", x).attr("x2", pointX).attr("y1", y).attr("y2", pointY).attr("stroke", radarColor["link"])
                    .attr("class", "radar " + type + " ID_" + ID)

                pointX = x
                pointY = y
                pathData += " L " + pointX + " " + pointY
            }
        }
        pathData += " z"
        let theBackColor = backColor.substring(0, backColor.length - 2) + "1)"
        let x = (radarX - Math.sin(0 / axisNum * 2 * Math.PI) * radarR * (vectorData[0] / radarColor["limit"])),
            y = (radarY - Math.cos(0 / axisNum * 2 * Math.PI) * radarR * (vectorData[0] / radarColor["limit"]))

        svg.append("line").attr("x1", x).attr("x2", pointX).attr("y1", y).attr("y2", pointY).attr("stroke", radarColor["link"])
            .attr("class", "radar " + type + " ID_" + ID)
        if (type === 'jobLegend') {
            svg.append("path").attr("d", pathData).attr("fill", "#fbfbfb").attr("stroke", radarColor["link"])
                .attr("class", "radar " + type + " ID_" + ID).attr("id", "ID_" + ID + "_backBack")
        }
        svg.append("path").attr("d", pathData).attr("fill", theBackColor).attr("stroke", radarColor["link"])
            .attr("class", "radar " + type + " ID_" + ID).attr("id", "ID_" + ID + "_back")
    }
}

export function vector_text(svg, x, y, text_array) {
    for (const index in text_array) {
        svg.append("text")
            .attr("font-size", 8).attr("text-anchor", "middle")
            .style("font-family", "sans-serif").style("font-weight", "350").style("fill", "rgba(33, 37, 41, 0.5)")
            .attr("x", (x) + "px")
            .attr("y", (y + 8 * index) + "px")
            .attr("class", "vector_text sample radar")
            .text(text_array[index])
    }
}