import axios from 'axios';


const devApiUrl = process.env.NODE_ENV === 'development' ? "https://jobviz.datavizu.app" : "";
const GET_REQUEST = 'get';
const POST_REQUEST = 'post';
const CancelToken = axios.CancelToken

function request(url, params, type, callback, cancelList) {
    let func;
    if (type === GET_REQUEST) {
        func = axios.get(url, {
            cancelToken: new CancelToken(c => {
                cancelList.push(c)
            })
        });
    } else if (type === POST_REQUEST) {
        func = axios.post(url, params, {
            cancelToken: new CancelToken(c => {
                cancelList.push(c)
            })
        });
    }

    func.then((response) => {
        if (response.status === 200) {
            callback(response);
        } else {
            console.error(response);
        }
    })
        .catch((error) => {
            console.log(error);
        });
}

export function getSkills(callback) {
    const url = `${devApiUrl}/skills`;

    let params = {}

    request(url, params, GET_REQUEST, callback, []);
}

export function getPosts(callback) {
    const url = `${devApiUrl}/posts`;

    let params = {}

    request(url, params, GET_REQUEST, callback, []);
}

export function getOtherInfo(postID_list, typeID, callback, cancelList) {
    const url = `${devApiUrl}/othersinfo`

    let params = new URLSearchParams()
    params.append("postID", postID_list)
    params.append("type", typeID)

    request(url, params, POST_REQUEST, callback, cancelList);
}

export function getJobInfo(post_name, location, qualification, industries, experiences, callback) {

    const url = `${devApiUrl}/jobinfo`
    let params = new URLSearchParams()
    params.append("postID", post_name)
    params.append("location", location)
    params.append("qualification", qualification)
    params.append("industries", industries)
    params.append("experiences", experiences)
    params.append("num", 100)

    request(url, params, POST_REQUEST, callback, []);
}

// function getClusterInfoOne(url, postID, locations, qualifications, industries, experiences,) {
//     let params = new URLSearchParams()
//     params.append("postID", postID)
//     params.append("location", locations)
//     params.append("qualification", qualifications)
//     params.append("industries", industries)
//     params.append("experiences", experiences)
//     params.append("num", 1000)
//     return axios.post(url, params)
// }

export function getClusterInfo(postID, locations, qualifications, industries, experiences, callback) {

    const url = `${devApiUrl}/cluster_info`

    let params = new URLSearchParams()
    params.append("postID", postID)
    params.append("location", locations)
    params.append("qualification", qualifications)
    params.append("industries", industries)
    params.append("experiences", experiences)
    params.append("num", 1000)

    request(url, params, POST_REQUEST, callback, []);
    // let requestList=[]
    // for (const post of postID) {
    //     requestList.push(getClusterInfoOne(url,post,locations,qualifications,industries,experiences))
    // }
    //
    // axios.all(requestList).then((response) => {
    //     let res=[]
    //     for (const responseOne of response) {
    //         if (responseOne.status===200) {
    //             for (const cluster of responseOne.data) {
    //                 res.push(cluster)
    //             }
    //         } else {
    //             console.error(responseOne)
    //         }
    //     }
    //     if (res.length!==0) {
    //         callback(res)
    //     } else {
    //         console.error('request error!')
    //     }
    // }).catch((error) => {
    //     console.log(error);
    // });
}

export function getDetails(id, callback) {
    const url = `${devApiUrl}/detail`
    let params = new URLSearchParams()
    params.append("id", id)

    console.log(id)

    request(url, params, POST_REQUEST, callback, []);
}
